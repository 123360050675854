
export default {
    physicalHealthScoreData: [
        {
            id: 1,
            scoerKey: 'student_BMI_score',
            key: 'student_BMI',
            name: 'BMI',
            indep: 'BMI',
            unit: '千克/米²',
            icon: require('@/assets/img/115.png'),
            value: 0,
            score: 0,
            levelNum: 0,
            levelName: ''
        },
        {
            id: 2,
            scoerKey: 'student_lung_score',
            key: 'student_lung',
            name: '肺活量',
            indep: 'LUNG',
            unit: '毫升',
            icon: require('@/assets/img/116.png'),
            value: 0,
            score: 0,
            levelNum: 0,
            levelName: ''
        },
        {
            id: 3,
            scoerKey: 'student_50meters_score',
            key: 'student_50meters',
            name: '50米跑',
            indep: 'RUNNING_50METERS',
            unit: '秒',
            icon: require('@/assets/img/117.png'),
            value: 0,
            score: 0,
            levelNum: 0,
            levelName: ''
        },
        {
            id: 4,
            scoerKey: 'student_1min_rope_score',
            key: 'student_1min_rope',
            name: '1分钟跳绳',
            indep: 'JUMP',
            unit: '次',
            icon: require('@/assets/img/119.png'),
            value: 0,
            score: 0,
            levelNum: 0,
            levelName: ''
        },
        {
            id: 5,
            scoerKey: 'student_stretch_score',
            key: 'student_stretch',
            name: '坐位体前屈',
            indep: 'STRETCH',
            unit: '厘米',
            icon: require('@/assets/img/118.png'),
            value: 0,
            score: 0,
            levelNum: 0,
            levelName: ''
        },
        {
            id: 6,
            scoerKey: 'student_1min_sit_up_score',
            key: 'student_1min_sit_up',
            name: '1分钟仰卧起坐',
            indep: 'SIT_UP',
            unit: '厘米',
            icon: require('@/assets/img/120.png'),
            value: 0,
            score: 0,
            levelNum: 0,
            levelName: ''
        },
        {
            id: 7,
            scoerKey: 'student_50meters_x8_score',
            key: 'student_50meters_x8',
            name: '50米×8往返跑',
            indep: 'RUNNING_50METERS_X8',
            unit: '分·秒',
            icon: require('@/assets/img/117.png'),
            value: 0,
            score: 0,
            levelNum: 0,
            levelName: ''
        },
        {
            id: 8,
            scoerKey: 'student_standing_jump_score',
            key: 'student_standing_jump',
            indep: 'STANDING_JUMP',
            name: '立定跳远',
            icon: require('@/assets/img/121.png'),
            value: 0,
            score: 0,
            levelNum: 0,
            levelName: ''
        },
    ],
    physicalHealthIndicator: [
        {
            id: 1,
            key: 'student_BMI_score',
            name: 'BMI',
            max: 100,
            min: 0,
            value: 0
        },
        {
            id: 2,
            key: 'student_lung_score',
            name: '肺活量',
            max: 100,
            min: 0,
            value: 0
        },
        {
            id: 3,
            key: 'student_50meters_score',
            name: '50米跑',
            max: 100,
            min: 0,
            value: 0
        },
        {
            id: 4,
            key: 'student_1min_rope_score',
            name: '1分钟跳绳',
            max: 100,
            min: 0,
            value: 0
        },
        {
            id: 5,
            key: 'student_stretch_score',
            name: '坐位体前屈',
            max: 100,
            min: 0,
            value: 0
        },
        {
            id: 6,
            key: 'student_1min_sit_up_extra_score',
            name: '1分钟仰卧起坐',
            max: 100,
            min: 0,
            value: 0
        },
        {
            id: 7,
            key: 'student_50meters_x8_score',
            name: '50米×8往返跑',
            max: 100,
            min: 0,
            value: 0
        },
        {
            id: 8,
            key: 'student_standing_jump_score',
            name: '立定跳远',
            max: 100,
            min: 0,
            value: 0
        },
    ],
    option2: {
        xAxis: {
            type: "category",
            data: [],
            axisLabel: {
                formatter: (value) => {
                    // return value.split(` `)[0]; // return this.formatXlabel (value)
                    return value.split(` `).join(`\n`); // return this.formatXlabel (value)
                },
            },
        },
        yAxis: {
            type: "value",
        },
        grid: {
            bottom: "20%",
            top: "20%",
            left: "0%",
            right: "0%",
        },
        dataZoom: [
            {
                orient: "horizontal",

                show: true, //控制滚动条显示隐藏

                realtime: true, //拖动滚动条时是否动态的更新图表数据

                height: 15, //滚动条高度

                start: 0, //滚动条开始位置（共100等份）

                end: 100, //滚动条结束位置

                bottom: "4%",

                zoomLock: false, //控制面板是否进行缩放
            },

            {
                type: "inside",

                brushSelect: true,

                start: 0,

                end: 100,

                xAxisIndex: [0],
            },
        ],
        tooltip: {
            formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
        },
        series: [
            {
                data: [],
                type: "line",
            },
        ],
    },

    option3: {
        title: {
            text: "",
        },
        legend: { show: true },
        tooltip: {
            trigger: "axis",
        },
        radar: {
            // shape: 'circle',
            indicator: [
                { name: "BMI", max: 100 },
                { name: "肺活量", max: 100 },
                { name: "50米跑", max: 100 },
                { name: "1分钟跳绳", max: 100 },
                { name: "坐位体前屈", max: 100 },
                { name: "1分钟仰卧起坐", max: 100 },
                { name: "50米×8往返跑", max: 100 },
                { name: "立定跳远", max: 100 },
            ],
            center: ["50%", "50%"],
            splitArea: {
                // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                show: true,
                areaStyle: {
                    // 分隔区域的样式设置。
                    color: [
                        "rgba(250,250,250,0.3)",
                        "rgba(200,200,200,0.3)",
                        "rgba(200,200,200,0.3)",
                    ],
                    // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                },
            },
        },
        series: [
            {
                areaStyle: {},
                tooltip: {
                    trigger: "item",
                },
                name: "体质健康综合分析",
                type: "radar",
                data: [
                    {
                        value: [],
                        name: "",
                    },
                ],
            },
        ],
    },

    // BMI
    option4: {
        title: {
            text: "BMI",
            left: "center", // 标题居中
            bottom: 0, // 距离上边框20像素
        },
        xAxis: {
            type: "category",
            data: [],
            axisLabel: {
                formatter: (value) => {
                    // return value.split(` `)[0]; // return this.formatXlabel (value)
                    return value.split(` `).join(`\n`); // return this.formatXlabel (value)
                },
                rotate: 45,
            },
        },
        yAxis: {
            type: "value",
        },
        grid: {
            bottom: "20%",
            top: "10%",
            left: "8%",
            right: "5%",
        },
        dataZoom: [
            {
                orient: "horizontal",

                show: false, //控制滚动条显示隐藏

                realtime: true, //拖动滚动条时是否动态的更新图表数据

                height: 15, //滚动条高度

                start: 0, //滚动条开始位置（共100等份）

                end: 100, //滚动条结束位置

                bottom: "4%",

                zoomLock: false, //控制面板是否进行缩放
            },

            {
                type: "inside",

                brushSelect: true,

                start: 0,

                end: 100,

                xAxisIndex: [0],
            },
        ],
        tooltip: {
            formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
        },
        series: [
            {
                data: [],
                type: "bar",
                color: "#5087EC",
                barMaxWidth: 30,
            },
        ],
    },
    // 肺活量
    option5: {
        title: {
            text: "肺活量",
            left: "center", // 标题居中
            bottom: 0, // 距离上边框20像素
        },
        xAxis: {
            type: "category",
            data: [],
            axisLabel: {
                formatter: (value) => {
                    // return value.split(` `)[0]; // return this.formatXlabel (value)
                    return value.split(` `).join(`\n`); // return this.formatXlabel (value)
                },
                rotate: 45,
            },
        },
        yAxis: {
            type: "value",
        },
        grid: {
            bottom: "20%",
            top: "10%",
            left: "8%",
            right: "5%",
        },
        dataZoom: [
            {
                orient: "horizontal",

                show: false, //控制滚动条显示隐藏

                realtime: true, //拖动滚动条时是否动态的更新图表数据

                height: 15, //滚动条高度

                start: 0, //滚动条开始位置（共100等份）

                end: 100, //滚动条结束位置

                bottom: "4%",

                zoomLock: false, //控制面板是否进行缩放
            },

            {
                type: "inside",

                brushSelect: true,

                start: 0,

                end: 100,

                xAxisIndex: [0],
            },
        ],
        tooltip: {
            formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
        },
        series: [
            {
                data: [],
                type: "bar",
                color: "#5087EC",
                barMaxWidth: 30,
            },
        ],
    },
    // 50米跑
    option6: {
        title: {
            text: "50米跑",
            left: "center", // 标题居中
            bottom: 0, // 距离上边框20像素
        },
        xAxis: {
            type: "category",
            data: [],
            axisLabel: {
                formatter: (value) => {
                    // return value.split(` `)[0]; // return this.formatXlabel (value)
                    return value.split(` `).join(`\n`); // return this.formatXlabel (value)
                },
                rotate: 45,
            },
        },
        yAxis: {
            type: "value",
        },
        grid: {
            bottom: "20%",
            top: "10%",
            left: "8%",
            right: "5%",
        },
        dataZoom: [
            {
                orient: "horizontal",

                show: false, //控制滚动条显示隐藏

                realtime: true, //拖动滚动条时是否动态的更新图表数据

                height: 15, //滚动条高度

                start: 0, //滚动条开始位置（共100等份）

                end: 100, //滚动条结束位置

                bottom: "4%",

                zoomLock: false, //控制面板是否进行缩放
            },

            {
                type: "inside",

                brushSelect: true,

                start: 0,

                end: 100,

                xAxisIndex: [0],
            },
        ],
        tooltip: {
            formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
        },
        series: [
            {
                data: [],
                type: "bar",
                color: "#5087EC",
                barMaxWidth: 30,
            },
        ],
    },
    // 坐位体前屈
    option7: {
        title: {
            text: "坐位体前屈",
            left: "center", // 标题居中
            bottom: 0, // 距离上边框20像素
        },
        xAxis: {
            type: "category",
            data: [],
            axisLabel: {
                formatter: (value) => {
                    // return value.split(` `)[0]; // return this.formatXlabel (value)
                    return value.split(` `).join(`\n`); // return this.formatXlabel (value)
                },
                rotate: 45,
            },
        },
        yAxis: {
            type: "value",
        },
        grid: {
            bottom: "20%",
            top: "10%",
            left: "8%",
            right: "5%",
        },
        dataZoom: [
            {
                orient: "horizontal",

                show: false, //控制滚动条显示隐藏

                realtime: true, //拖动滚动条时是否动态的更新图表数据

                height: 15, //滚动条高度

                start: 0, //滚动条开始位置（共100等份）

                end: 100, //滚动条结束位置

                bottom: "4%",

                zoomLock: false, //控制面板是否进行缩放
            },

            {
                type: "inside",

                brushSelect: true,

                start: 0,

                end: 100,

                xAxisIndex: [0],
            },
        ],
        tooltip: {
            formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
        },
        series: [
            {
                data: [],
                type: "bar",
                color: "#5087EC",
                barMaxWidth: 30,
            },
        ],
    },
    // 1分钟跳绳
    option8: {
        title: {
            text: "1分钟跳绳",
            left: "center", // 标题居中
            bottom: 0, // 距离上边框20像素
        },
        xAxis: {
            type: "category",
            data: [],
            axisLabel: {
                formatter: (value) => {
                    // return value.split(` `)[0]; // return this.formatXlabel (value)
                    return value.split(` `).join(`\n`); // return this.formatXlabel (value)
                },
                rotate: 45,
            },
        },
        yAxis: {
            type: "value",
        },
        grid: {
            bottom: "20%",
            top: "10%",
            left: "8%",
            right: "5%",
        },
        dataZoom: [
            {
                orient: "horizontal",

                show: false, //控制滚动条显示隐藏

                realtime: true, //拖动滚动条时是否动态的更新图表数据

                height: 15, //滚动条高度

                start: 0, //滚动条开始位置（共100等份）

                end: 100, //滚动条结束位置

                bottom: "4%",

                zoomLock: false, //控制面板是否进行缩放
            },

            {
                type: "inside",

                brushSelect: true,

                start: 0,

                end: 100,

                xAxisIndex: [0],
            },
        ],
        tooltip: {
            formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
        },
        series: [
            {
                data: [],
                type: "bar",
                color: "#5087EC",
                barMaxWidth: 30,
            },
        ],
    },
    // 1分钟仰卧起坐
    option9: {
        title: {
            text: "1分钟仰卧起坐",
            left: "center", // 标题居中
            bottom: 0, // 距离上边框20像素
        },
        xAxis: {
            type: "category",
            data: [],
            axisLabel: {
                formatter: (value) => {
                    // return value.split(` `)[0]; // return this.formatXlabel (value)
                    return value.split(` `).join(`\n`); // return this.formatXlabel (value)
                },
                rotate: 45,
            },
        },
        yAxis: {
            type: "value",
        },
        grid: {
            bottom: "20%",
            top: "10%",
            left: "8%",
            right: "5%",
        },
        dataZoom: [
            {
                orient: "horizontal",

                show: false, //控制滚动条显示隐藏

                realtime: true, //拖动滚动条时是否动态的更新图表数据

                height: 15, //滚动条高度

                start: 0, //滚动条开始位置（共100等份）

                end: 100, //滚动条结束位置

                bottom: "4%",

                zoomLock: false, //控制面板是否进行缩放
            },

            {
                type: "inside",

                brushSelect: true,

                start: 0,

                end: 100,

                xAxisIndex: [0],
            },
        ],
        tooltip: {
            formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
        },
        series: [
            {
                data: [],
                type: "bar",
                color: "#5087EC",
                barMaxWidth: 30,
            },
        ],
    },
}
