<template>
  <div class="box">
    <div class="align-center space-between">
      <div class="title">概况</div>
      <div class="time_filtter">
        <el-date-picker
          v-model="pickerTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="pickerChange"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
    </div>

    <div class="align-center wb-100 mt-10">
      <div class="align-center overview_box overview_box1 space-between">
        <div class="name">
          {{
            curentStudent && curentStudent.name
              ? curentStudent.name.substr(curentStudent.name.length - 1)
              : "---"
          }}
        </div>
        <div class="information">
          <div class="align-center nowrap mt-4">
            姓名：{{
              curentStudent && curentStudent.name ? curentStudent.name : "---"
            }}
          </div>
          <div class="align-center nowrap mt-4">
            性别：
            {{
              curentStudent ? (curentStudent.gender == 0 ? "女" : "男") : "---"
            }}
          </div>
          <div class="align-center nowrap mt-4">
            班号：{{
              curentStudent && curentStudent.id ? curentStudent.id : "---"
            }}
          </div>
          <div class="align-center nowrap mt-4">
            班级：{{ levelName || "---" }}
            {{
              curentStudent && curentStudent.class_name
                ? curentStudent.class_name
                : "---"
            }}
          </div>
        </div>
      </div>

      <div
        class="overview_box2 overview_box align-center pointer row-cener"
        @mouseleave="handleAttendanceOut"
        @mouseenter="handleAttendanceOver"
      >
        <div class="column align-center echart" ref="attendanceRef"></div>
        <div class="echartText column row-center" v-if="isAttendanceOver">
          <div class="align-center nowrap">出勤：<span>0次</span></div>
          <div class="align-center nowrap mt-4">缺勤：<span>0次</span></div>
        </div>
      </div>

      <div
        class="overview_box2 overview_box align-center pointer row-cener"
        @mouseleave="handleCompletionOut"
        @mouseenter="handleCompletionOver"
      >
        <div class="column align-center echart" ref="completionRef"></div>
        <div class="echartText column row-center" v-if="isCompletionOver">
          <div class="align-center nowrap">完成：<span>0次</span></div>
          <div class="align-center nowrap mt-4">未完成：<span>0次</span></div>
        </div>
      </div>

      <div class="overview_box overview_box2 column space-between pb-10">
        <div class="box_title">智慧跑步</div>
        <div class="align-center">
          <div class="icon">
            <img src="@/assets/img/117.png" alt="" srcset="" />
          </div>
          <div class="text_list">
            <div class="nowrap">参与次数：<span>0次</span></div>
            <div class="nowrap">总公里数：<span>0KM</span></div>
          </div>
        </div>
      </div>

      <div class="overview_box overview_box2 column space-between pb-10">
        <div class="box_title">赛事参与</div>
        <div class="align-center">
          <div class="icon">
            <img src="@/assets/img/127.png" alt="" srcset="" />
          </div>
          <div class="text_list">
            <div class="nowrap">参与次数：<span>0次</span></div>
            <div class="nowrap">获奖次数：<span>0次</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="table_contain_box">
      <class-room-table />
      <jop-records-table />
      <smart-runing-table />
      <event-records-table />
    </div>
  </div>
</template>

<script>
import {
  Attendance_options,
  Jop_Completion_options,
} from "../../enums/dailyPerformance.js";
import * as echarts from "echarts";
import ClassRoomTable from "./ClassRoomTable.vue";
import JopRecordsTable from "./JopRecordsTable.vue";
import SmartRuningTable from "./SmartRuningTable.vue";
import EventRecordsTable from "./EventRecordsTable.vue";
export default {
  components: {
    ClassRoomTable,
    JopRecordsTable,
    SmartRuningTable,
    EventRecordsTable,
  },
  props: {
    curentStudent: {
      type: Object,
      default: {},
    },
    levelName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pickerTime: [],
      formData: {
        startTime: "",
        endTime: "",
      },
      attendance: null,
      attendanceOptions: Attendance_options,
      isAttendanceOver: false,

      completion: null,
      completionOptions: Jop_Completion_options,
      isCompletionOver: false,
    };
  },
  created() {},
  mounted() {
    this.attendanceInit();
    this.completionInit();

    this.WatchResize();
  },
  methods: {
    handleAttendanceOut() {
      this.isAttendanceOver = false;
    },
    handleAttendanceOver() {
      this.isAttendanceOver = true;
    },
    handleCompletionOut() {
      this.isCompletionOver = false;
    },
    handleCompletionOver() {
      this.isCompletionOver = true;
    },
    pickerChange($event) {
      this.formData.startTime = $event[0];
      this.formData.endTime = $event[1];
    },

    attendanceInit() {
      const attendanceRef = this.$refs.attendanceRef;
      this.attendance = echarts.init(attendanceRef);
      this.attendance.setOption(this.attendanceOptions);
    },

    completionInit() {
      const completionRef = this.$refs.completionRef;
      this.completion = echarts.init(completionRef);
      this.completion.setOption(this.completionOptions);
    },
    WatchResize() {
      const that = this;
      window.addEventListener("resize", function () {
        that.attendance.resize();
        that.completion.resize();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  color: #333;
  font-size: 22px;
  font-weight: bold;
}

.overview_box {
  background-color: #f1f3fd;
  border-radius: 10px;
  margin-right: 10px;
  padding-left: 14px;
  padding-right: 14px;
  &:nth-last-child(1){
    margin-right: 0px;
  }
}
.overview_box1 {
  width: 22%;
  height: 140px;
  display: flex;
  justify-content: space-between;
  .name {
    font-size: 40px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #bbbbbb;
  }
  .information {
    width: calc(100% - 90px);
    color: #333;
    font-size: 16px;
  }
}

.overview_box2 {
  width: 20%;
  height: 140px;
  .echart {
    width: 100px;
    height: 140px;
  }
  .echartText {
    width: calc(100% - 100px);
    height: 100%;
    font-size: 16px;
    span {
      font-size: 20px;
    }
  }
  .box_title {
    color: #333;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    width: 100%;
    text-align: right;
  }
  .icon {
    width: 50px;
    height: 56px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text_list {
    color: #333;
    font-size: 18px;
    margin-left: 13px;
  }
}



.table_contain_box {
  width: 98%;
}

::v-deep .table_box {
  margin-top: 26px;
  width: 100%;
  .table_title {
    font-size: 24px;
    color: #333;
    margin-bottom: 22px;
    font-weight: bold;
  }
  .table_contain {
    border: 1px solid #baccfb;
    border-radius: 10px;
    padding: 30px 10px 30px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
  }
}

::v-deep .table_no_data {
  height: 629px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

::v-deep .el-table__header-wrapper .el-table__header th {
  background-color: #f1f3fd;
  color: #444444;
  font-size: 18px;
}

::v-deep
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background-color: #f7f8ff;
}

::v-deep .pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.pb-10 {
  padding-bottom: 10px;
}

.box {
  width: 100%;
  height: 100%;
}
.mt-10 {
  margin-top: 10px;
}
.row-center {
  display: flex;
  justify-content: center;
}
.align-center {
  display: flex;
  align-items: center;
}
.row-cener {
  display: flex;
  justify-content: center;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.column {
  display: flex;
  flex-direction: column;
}

.mt-4 {
  margin-top: 4px;
}
.pointer {
  cursor: pointer;
}
.wb-100 {
  width: 100%;
}
.nowrap {
  white-space: nowrap;
}
</style>