<template>
  <div class="table_box">
    <div class="table_title">作业记录</div>
    <div class="table_contain">
      <div v-if="datalist.length">
        <el-table
          :data="datalist"
          stripe
          style="width: 100%"
          height="540"
          fit
          highlight-current-row
          header-row-class-name="th-class"
        >
          <el-table-column
            v-for="(item, index) in column"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :show-overflow-tooltip="item.tooltip"
            align="center"
          >
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            :page-sizes="pageSizes"
            :page-size="page.pageSize"
            layout="sizes, prev, pager, next"
            :total="page.total"
            background
            class="mt-20"
          >
          </el-pagination>
        </div>
      </div>
      <div class="table_no_data" v-else>
        <el-empty description="暂无数据"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { Event_Records_Column } from "../../enums/dailyPerformance.js";
export default {
  data() {
    return {
      pageSizes: [10, 20, 30, 40],
      column: Event_Records_Column,
      datalist: [],
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
    };
  },
  created() {
    this.setTableData();
  },
  methods: {
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.setTableData();
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.setTableData();
    },
    setTableData() {
      let obj = {};
      let list = [];
      this.datalist = list;
    },
  },
};
</script>

<style lang='less'>
.t-greed {
  color: #32c667;
}

.t-red {
  color: #f24135;
}

.mt-20 {
  margin-top: 20px;
}

</style>