<template>
  <div class="box222">
    <div class="header_box flexSb">
      <div @click="handleClass()" class="flexSt header">
        <img src="@/assets/img/126.png" alt="" />
        <div>班级健康档案</div>
      </div>

      <div class="flexSt hader_2">
        <div class="hader_2_title">班级：</div>
        <el-select
          class="sel_t"
          @change="handleChanegLevel"
          v-model="level_id"
          placeholder="请选择">
          <el-option
            v-for="item in level_li"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select
          class="sel_t"
          @change="handleChanegclassId"
          style="margin-left: 25px"
          v-model="class_id"
          placeholder="请选择班级">
          <el-option
            v-for="item in classlist"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="main flexSb">
      <div class="left">
        <!-- <div class="tiitle">学生列表</div> -->
        <el-tabs v-model="activeName" @tab-click="handleChangeStudent">
          <el-tab-pane label="全部" name="-1"></el-tab-pane>
          <el-tab-pane label="男生" name="1"></el-tab-pane>
          <el-tab-pane label="女生" name="0"></el-tab-pane>
        </el-tabs>
        <div class="left_item">
          <template v-if="tableData && tableData.length">
            <div
              @click="handleclick(index, item)"
              :class="{ active: index == current }"
              v-for="(item, index) in tableData"
              :key="index"
              class="list_item">
              <!-- <div>{{ item.id }}</div> -->
              <div>{{ item.name }}</div>
            </div>
          </template>
          <template v-else>
            <div class="wh100 center">
              <el-empty description="暂无数据"></el-empty>
            </div>
          </template>
        </div>
      </div>
      <div class="right">
        <!--<div class="right_header flexSc">
          <div
            @click="handleChangeheader(0)"
            class="right_header_item"
            :class="{ active_header: currentRight == 0 }"
          >
            体质健康
          </div>
          <div
            @click="handleChangeheader(1)"
            class="right_header_item"
            :class="{ active_header: currentRight == 1 }"
          >
            日常表现
          </div>
        </div>-->

        <div class="right_main">
          <div v-show="currentRight == 0">
            <div class="flexSb">
              <div class="flexSt">
                <div class="right_title">个人体测成绩</div>
                <div class="flexSt img_list" @click="handleReportClick">
                  <img class="img1_1" src="@/assets/img/113.png" alt="" />
                  <div>报告</div>
                </div>

                <div class="flexSt img_list ml-20">
                  体测日期：{{
                    school_record_time ? school_record_time : "---"
                  }}
                </div>
              </div>
              <el-popover
                placement="bottom"
                width="153"
                trigger="click"
                v-model="isHistorical">
                <div class="historical_select_time">
                  <div
                    class="select_time_link"
                    :class="
                      item.id == school_record_id ? 'select_time_active' : ''
                    "
                    @click="handleTimeClick(item)"
                    v-for="(item, index) in historicalList"
                    :key="index">
                    {{ item.record_time }}
                  </div>
                </div>
                <div slot="reference" class="align-center pointer img_list">
                  <div class="icon_img center">
                    <img src="@/assets/img/125.png" alt="" />
                  </div>
                  <div>历史体测</div>
                </div>
              </el-popover>
            </div>

            <div class="box1 flexSb">
              <div class="box1_left">
                <div class="box1_header flexSb">
                  <div class="name1">
                    {{
                      curentStudent && curentStudent.name
                        ? curentStudent.name.substr(
                            curentStudent.name.length - 1
                          )
                        : "---"
                    }}
                  </div>
                  <div>
                    <div class="name2">
                      姓名：{{
                        curentStudent && curentStudent.name
                          ? curentStudent.name
                          : "---"
                      }}
                    </div>
                    <div class="name2">
                      性别：
                      {{
                        curentStudent
                          ? curentStudent.gender == 0
                            ? "女"
                            : "男"
                          : "---"
                      }}
                    </div>
                    <div class="name2">
                      编号：{{
                        curentStudent && curentStudent.id
                          ? curentStudent.id
                          : "---"
                      }}
                    </div>
                    <div class="name2">
                      班级：{{ levelName || "---" }}
                      {{
                        curentStudent && curentStudent.class_name
                          ? curentStudent.class_name
                          : "---"
                      }}
                    </div>
                  </div>
                </div>
                <div class="box1_main">
                  <span v-if="student_data && student_data.total_score"
                    >{{ student_data.total_score }}分</span
                  ><span v-else>---</span>
                </div>
                <div class="box1_footer">
                  <div class="box1_bg">
                    <div class="hg">
                      {{
                        student_data && student_data.total_score_level_name
                          ? student_data.total_score_level_name
                          : "---"
                      }}
                    </div>
                    <img src="@/assets/img/114.png" alt="" />
                    <div class="zpf">总评分</div>
                  </div>
                </div>
              </div>
              <div class="box1_right align-center">
                <template
                  v-if="
                    physicalHealthIndicator && physicalHealthIndicator.length
                  ">
                  <div
                    class="prj"
                    v-for="(item, index) in physicalHealthIndicator"
                    :key="index">
                    <div class="prj_header flexSb">
                      <div
                        class="status"
                        :class="`status${item.levelNum ? item.levelNum : 3}`">
                        <div class="status_title">
                          {{ item.levelName ? item.levelName : "---" }}
                        </div>
                      </div>
                      <div>
                        <div class="prj_name">{{ item.name }}</div>
                        <div class="prj_dw">（{{ item.unit }}）</div>
                      </div>
                    </div>
                    <div class="flexSb prj_st">
                      <div class="flexSt">
                        <img class="prj_img" :src="item.icon" alt="" />
                        <div>
                          {{
                            item.score || item.score == 0
                              ? `${item.score}分`
                              : "---"
                          }}
                        </div>
                      </div>
                      <div>
                        {{ item.value || item.value == 0 ? item.value : "---" }}
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="wh100 center min-w900">
                    <el-empty description="暂无数据"></el-empty>
                  </div>
                </template>
              </div>
            </div>
            <div class="right_title">测试结论</div>
            <div class="box2 flexSb">
              <div
                class="box2_left"
                v-if="
                  personal_total_report_str &&
                  personal_part_str &&
                  personal_compare_str
                ">
                <div class="cont_box2" v-if="personal_total_report_str">
                  1.{{ personal_total_report_str }}
                </div>
                <div class="cont_box2" v-if="personal_part_str">
                  2.{{ personal_part_str }}
                </div>
                <div v-if="personal_compare_str">
                  3.{{ personal_compare_str }}
                </div>
              </div>
              <div class="box2_left" v-else>
                <div class="wh100 center min-w900">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
              <div class="box2_right" ref="box_1"></div>
            </div>
            <div class="right_title">体质健康趋势</div>

            <div class="box3 wh100">
              <template v-if="trendBarList && trendBarList.length">
                <div
                  class="box3_item"
                  v-for="(item, index) in trendBarList"
                  :key="index"
                  ref="trendBarRef"></div>
              </template>
              <template v-else>
                <div class="wh100 center">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </template>
            </div>
          </div>

          <div v-show="currentRight == 1" class="performance">
            <daily-performance
              :curentStudent="curentStudent"
              :levelName="levelName" />
          </div>

          <div class="" v-if="currentRight == 2">
            <middle-examination
              :physicalHealthIndicator="physicalHealthIndicator"
              :school_record_time="school_record_time"
              :trendBarList="trendBarListTwo"
              :student_data="student_data"
              :compare_data="compare_data"
              :curentStudent="curentStudent"
              :echartsOption="echartsOption"
              :levelName="levelName"
              :personal_total_report_str="personal_total_report_str"
              :personal_part_str="personal_part_str"
              :personal_compare_str="personal_compare_str" />
          </div>
        </div>
      </div>
    </div>

    <report-dialog
      :isReportDialog="isReportDialog"
      :school_record_time="school_record_time"
      :physicalHealthIndicator="physicalHealthIndicator"
      :trendBarList="trendBarListTwo"
      :student_data="student_data"
      :compare_data="compare_data"
      :curentStudent="curentStudent"
      :echartsOption="echartsOption"
      :levelName="levelName"
      :personal_total_report_str="personal_total_report_str"
      :personal_part_str="personal_part_str"
      :personal_compare_str="personal_compare_str"
      @handleClose="handleClose" />
  </div>
</template>
<script>
import { getSchoolID } from "@/utils/auth";
import * as echarts from "echarts";
import {
  studentHealth,
  projectType,
  studentHistoricalHealth,
  getStudentphysical,
  getPhysicalDiagramData,
  getHistoricalMeasurement,
} from "@/api/physical";
import DailyPerformance from "./components/studentPhysical/DailyPerformance.vue";
import ReportDialog from "./components/studentPhysical/ReportDialog.vue";
import MiddleExamination from "./components/studentPhysical/MiddleExamination.vue";
import studentPhysicaldetailEnum from "./enums/studentPhysicaldetailEnum.js";
export default {
  components: {
    DailyPerformance,
    ReportDialog,
    MiddleExamination,
  },
  data() {
    return {
      health: {
        HEIGHT: "",
        WEIGHT: "",
        BMI: "",
        STRETCH: "",
        LUNG: "",
        JUMP: "",
        RUNNING_50METERS: "",
        RUNNING_800METERS: "",
      },
      typeOptions: [],
      prjtype: "",
      levelName: "",
      tableData: [],
      tableData2: [],
      student_data: null,
      compare_data: null,
      bookmark: null,
      bookmarkList: [
        {
          bookmark: "grade",
          name: "年级",
        },
        {
          bookmark: "class",
          name: "班级",
        },
        {
          bookmark: "school",
          name: "学校",
        },
      ],
      compareRateEnum: {
        // BMI
        student_BMI_score: 0,
        // 肺活量
        student_lung_score: 0,
        // 50 米跑
        student_50meters_score: 0,
        // 1分钟跳绳得分
        student_1min_rope_score: 0,
        // 坐位体前屈
        student_stretch_score: 0,
        // 1分钟仰卧起坐
        student_1min_sit_up_extra_score: 0,
        //  50米×8往返跑
        student_50meters_x8_score: 0,
        //  立定跳远
        student_standing_jump_score: 0,
      },
      physicalHealthIndicator: [],
      physicalHealthScoreData: JSON.parse(
        JSON.stringify(studentPhysicaldetailEnum.physicalHealthScoreData)
      ),
      curentStudent: {
        name: "",
        gender: "",
        class_name: "",
        id: "",
      },
      personal_total_report_str: "",
      personal_part_str: "",
      personal_compare_str: "",
      classlist: [],
      level_li: [],
      class_id: "",
      level_id: "",
      value: "",
      options: [],
      current: 0,
      historical: "",
      barWidth: 30,
      option2: {
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            formatter: (value) => {
              // return value.split(` `)[0]; // return this.formatXlabel (value)
              return value.split(` `).join(`\n`); // return this.formatXlabel (value)
            },
          },
        },
        yAxis: {
          type: "value",
        },
        grid: {
          bottom: "20%",
          top: "20%",
          left: "0%",
          right: "0%",
        },
        dataZoom: [
          {
            orient: "horizontal",

            show: true, //控制滚动条显示隐藏

            realtime: true, //拖动滚动条时是否动态的更新图表数据

            height: 15, //滚动条高度

            start: 0, //滚动条开始位置（共100等份）

            end: this.endValue, //滚动条结束位置

            bottom: "4%",

            zoomLock: false, //控制面板是否进行缩放
          },

          {
            type: "inside",

            brushSelect: true,

            start: 0,

            end: 100,

            xAxisIndex: [0],
          },
        ],
        tooltip: {
          formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
        },
        series: [
          {
            data: [],
            type: "line",
          },
        ],
      },

      option3: {
        title: {
          text: "",
        },
        legend: { show: true },
        tooltip: {
          trigger: "axis",
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "BMI", max: 100 },
            { name: "肺活量", max: 100 },
            { name: "50米跑", max: 100 },
            { name: "1分钟跳绳", max: 100 },
            { name: "坐位体前屈", max: 100 },
            { name: "1分钟仰卧起坐", max: 100 },
            { name: "50米×8往返跑", max: 100 },
            { name: "立定跳远", max: 100 },
          ],
          center: ["50%", "50%"],
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: [
                "rgba(250,250,250,0.3)",
                "rgba(200,200,200,0.3)",
                "rgba(200,200,200,0.3)",
              ],
              // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            },
          },
        },
        series: [
          {
            areaStyle: {},
            tooltip: {
              trigger: "item",
            },
            name: "体质健康综合分析",
            type: "radar",
            data: [
              {
                value: [],
                name: "",
              },
            ],
          },
        ],
      },
      trendBarChart: {
        title: {
          text: "BMI",
          left: "center", // 标题居中
          bottom: 0, // 距离上边框20像素
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            formatter: (value) => {
              // return value.split(` `)[0]; // return this.formatXlabel (value)
              return value.split(` `).join(`\n`); // return this.formatXlabel (value)
            },
            rotate: 45,
          },
        },
        yAxis: {
          type: "value",
          max: 100,
        },
        grid: {
          bottom: "20%",
          top: "10%",
          left: "8%",
          right: "5%",
        },
        dataZoom: [
          {
            orient: "horizontal",

            show: false, //控制滚动条显示隐藏

            realtime: true, //拖动滚动条时是否动态的更新图表数据

            height: 15, //滚动条高度

            start: 0, //滚动条开始位置（共100等份）

            end: this.endValue, //滚动条结束位置

            bottom: "4%",

            zoomLock: false, //控制面板是否进行缩放
          },

          {
            type: "inside",

            brushSelect: true,

            start: 0,

            end: 100,

            xAxisIndex: [0],
          },
        ],
        tooltip: {
          formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
        },
        series: [
          {
            data: [],
            type: "bar",
            color: "#5087EC",
            barMaxWidth: 30,
          },
        ],
      },
      trendBarList: [],
      trendBarListTwo: [],
      box1: "",
      activeName: "-1",
      currentRight: 0,
      isReportDialog: false,

      historicalList: [],
      school_record_id: "",
      isHistorical: false,
      school_record_time: "",
    };
  },
  computed: {
    echartsOption() {
      const { option2, option3 } = this;
      return {
        option2,
        option3,
      };
    },
  },
  created() {
    const { bookmark } = this.$route.query;
    this.bookmark = bookmark ? bookmark : "";
  },
  mounted() {
    this.school_id = getSchoolID();
    this.getHistoricalMeasurement();
    this.handleInitBox1();

    this.getConfig();
  },
  methods: {
    handleClass() {
      this.$router.push("/attainment");
    },
    // 清空
    handleClearRecord() {
      this.personal_part_str = "";
      this.personal_compare_str = "";
      this.personal_total_report_str = "";

      this.current = 0;
      this.physicalHealthIndicator = [];
    },
    handleTimeClick(record) {
      this.school_record_id = record.id;
      this.school_record_time = record.record_time;
      this.getStudentphysical();
      this.getPhysicalDiagramData();
      this.isHistorical = false;
    },
    async getHistoricalMeasurement() {
      const params = {
        school_id: this.school_id,
      };
      const { data } = await getHistoricalMeasurement(params);

      this.historicalList = data;
      if (data && data.length) {
        this.school_record_id = data[0].id;
        this.school_record_time = data[0].record_time;
      }
    },
    isCondition(key1, key2) {
      const { student_data } = this;
      const StringCondition = ["null", "undefined"];
      return (
        student_data &&
        (student_data[key1] ||
          StringCondition.includes(JSON.stringify(student_data[key1])) ||
          student_data[key2] ||
          StringCondition.includes(JSON.stringify(student_data[key2])))
      );
    },

    handleClose() {
      this.isReportDialog = false;
    },

    handleReportClick() {
      this.isReportDialog = true;
    },

    // 对比
    contrastData(record1, record2) {
      if (record1 < record2) {
        return "低于";
      } else {
        return "高于";
      }
    },

    // 获取体质讲课-个人体测成绩-整体数据
    async getStudentphysical() {
      if (!this.curentStudent) {
        this.student_data = null;
        this.compare_data = null;
        return;
      }
      const params = {
        student_id: this.curentStudent.id || "",
        school_record_id: this.school_record_id,
      };
      const { data, code } = await getStudentphysical(params);

      if (code == 1) {
        this.student_data = data.student_data;
        this.compare_data =
          data.compare_data && data.compare_data.length
            ? data.compare_data[0]
            : null;
        this.personal_total_report_str = data.personal_total_report_str;
        this.personal_part_str = data.personal_part_str;
        this.personal_compare_str = data.personal_compare_str;

        if (
          this.student_data &&
          this.student_data.student_weight &&
          this.student_data.student_height
        ) {
          this.student_data.student_BMI = (
            this.student_data.student_weight /
            Math.pow(this.student_data.student_height / 100, 2)
          ).toFixed(1);
        }

        const dataDisplayList =
          studentPhysicaldetailEnum.physicalHealthScoreData.map((item) => {
            let value = 0;
            let score = 0;
            let levelNum = 0;
            let levelName = "";
            data.student_data &&
              typeof data.student_data == "object" &&
              Object.keys(data.student_data).forEach((key) => {
                if (item.key == key) {
                  value = data.student_data[key];
                }

                if (item.scoerKey == key) {
                  score = data.student_data[key];

                  let k = key.split("_score")[0];
                  levelNum = data.student_data[`${k}_level_num`];
                  levelName = data.student_data[`${k}_level_name`];
                }
              });

            return {
              ...item,
              value,
              score,
              levelNum,
              levelName,
            };
          });

        this.physicalHealthIndicator = dataDisplayList.filter(
          (item) => item.value || item.score
        );

        if (data.student_data) {
          this.physicalHealthIndicator.forEach((item, index) => {
            Object.keys(data.student_data).forEach((key) => {
              if (item.key == key) {
                item.value = data.student_data[key];
              }
            });
          });

          let arr = this.physicalHealthIndicator.filter(
            (item) => item.score || item.score == 0
          );

          // 为0筛选
          let valueArr = arr.map((item) => item.score);
          let indicator = arr.map((item) => {
            return {
              name: item.name,
              max: 100,
              min: 0,
            };
          });
          this.option3.series[0].data[0].value = valueArr;
          this.option3.radar.indicator = indicator.length
            ? indicator
            : studentPhysicaldetailEnum.option3.radar.indicator;
        } else {
          this.option3.series[0].data[0].value = [];
          this.option3.radar.indicator =
            studentPhysicaldetailEnum.option3.radar.indicator;
        }
        this.box1.setOption(this.option3);

        this.setTrendBar();
        this.getPhysicalDiagramData();
      }
    },

    async getPhysicalDiagramData() {
      const that = this;
      const params = {
        student_id: this.curentStudent.id || "",
        school_record_id: this.school_record_id,
      };

      const { data, code } = await getPhysicalDiagramData(params);

      if (code == 1) {
        this.trendBarListTwo = [];
        this.trendBarListTwo = JSON.parse(JSON.stringify(that.trendBarList));
        data.forEach((item1) => {
          Object.values(item1).forEach((item2) => {
            for (let index = 0; index < that.trendBarList.length; index++) {
              const element = that.trendBarList[index];
              if (element && item2.type == element.indepKey) {
                that.trendBarList[index].xAxis.data = item2.x_data;
                that.trendBarList[index].series[0].data = item2.y_data;

                that.trendBarListTwo[index].xAxis.data = item2.x_data;
                that.trendBarListTwo[index].series[0].data = item2.y_data;
                that[`trendBar${index + 1}`].setOption(
                  that.trendBarList[index]
                );
              }
            }
          });
        });
      }
    },

    WatchResize() {
      const that = this;
      window.addEventListener("resize", function () {
        that.$refs.trendBarRef.forEach((item, index) => {
          that[`trendBar${index + 1}`].resize();
        });
      });
    },

    // 趋势图初始化
    setTrendBar() {
      const { physicalHealthIndicator, trendBarChart } = this;
      this.trendBarList = physicalHealthIndicator.length
        ? physicalHealthIndicator.map((item, index) => {
            if (!this[`trendBar${index + 1}`]) {
              this[`trendBar${index + 1}`] = null;
            }
            return {
              ...trendBarChart,
              indepKey: item.indep,
              title: {
                text: item.name,
                left: "center", // 标题居中
                bottom: 0, // 距离上边框20像素
              },
            };
          })
        : [];

      this.$nextTick(() => {
        const trendBarRefs = this.$refs.trendBarRef;
        this.trendBarList.forEach((item, index) => {
          this[`trendBar${index + 1}`] = echarts.init(trendBarRefs[index]);
          this[`trendBar${index + 1}`].setOption(item);
        });
        this.WatchResize();
      });
    },

    handleChangeheader(val) {
      this.currentRight = val;
    },
    handleChangeStudent($event) {
      const { tableData2 } = this;
      const index = $event.index * 1;
      switch (index) {
        case 1:
          // 男生
          this.tableData = tableData2.filter((item) => item.gender == 1);
          break;
        case 2:
          // 女生
          this.tableData = tableData2.filter((item) => item.gender == 0);
          break;
        default:
          this.tableData = tableData2;
          break;
      }
      this.handleclick(0, this.tableData[0]);
    },
    async handlegetStudentHealth() {
      if (!this.curentStudent) return;
      let data = {
        id: this.curentStudent.id || "",
      };
      await studentHealth(data).then((res) => {
        this.studentInfo = res.data;
        if (res.data) {
          this.health = res.data;
        } else {
          this.health = {
            HEIGHT: "",
            WEIGHT: "",
            BMI: "",
            STRETCH: "",
            LUNG: "",
            JUMP: "",
            RUNNING_50METERS: "",
            RUNNING_800METERS: "",
          };
        }
      });
    },
    handlechangeprjtype() {
      this.handlegetStudentHistoricalHealth();
    },
    async handlegetStudentHistoricalHealth() {
      if (!this.curentStudent) return;
      let data = {
        id: this.curentStudent.id || "",
        type: this.prjtype,
      };
    },
    async handleGetprojectType() {
      await projectType().then((res) => {
        this.typeOptions = res.data;
        if (res.data.length > 0) {
          this.prjtype = res.data[0].value;
        }
        this.handlegetStudentHistoricalHealth();
      });
    },
    // 获取年级
    async getConfig() {
      const res = await this.axios.get(
        "/config/level?school_id=" + this.school_id
      );
      if (res.code === 1) {
        this.level_li = res.data;
        if (res.data.length > 0) {
          this.level_id = res.data[0].id;
          this.levelName = res.data[0].name;
        }
        this.getClasses();
      }
    },
    // 获取班级
    async getClasses(id) {
      const res = await this.axios.get(
        `/classes?all=1&school_id=${this.school_id}&level_id=${this.level_id}`
      );
      if (res.code === 1) {
        this.classlist = res.data;
        if (res.data.length > 0) {
          if (id) {
            this.class_id = id;
            this.getStudent();
          } else {
            this.class_id = res.data[0].id;
            this.getStudent();
          }
        } else {
          this.class_id = "";
          this.tableData = [];
          this.handleGetprojectType();
        }
      }
    },
    // 获取班级下学生数据
    async getStudent() {
      const res = await this.axios.get(
        `/school/students/list?classes_id=${this.class_id}&school_id=${this.school_id}&grade=${this.level_id}`
      );

      if (res.code === 1) {
        this.tableData = res.data.data;
        this.tableData2 = res.data.data;
        this.curentStudent = res.data.data[0];
        this.handlegetStudentHealth();
        this.handleGetprojectType();

        this.getStudentphysical();

        console.log(this.tableData.length);

        // if (this.tableData.length <= 0) {
        //   this.dialogImportClass = true;
        // }
      }
    },

    handleChanegLevel() {
      console.log(this.level_id);
      this.class_id = "";
      this.handleClearRecord();
      this.level_li.forEach((item) => {
        if (item.id == this.level_id) {
          this.levelName = item.name;
        }
      });

      this.getClasses();
    },
    handleChanegclassId() {
      this.handleClearRecord();
      this.getStudent();
    },
    handleclick(index, item) {
      this.current = index;
      this.curentStudent = item;
      this.handlegetStudentHealth();
      this.handlegetStudentHistoricalHealth();

      this.getStudentphysical();
    },
    handleBack(val) {
      this.$router.go(-val);
    },
    // 历史健康分析
    handleInitclass_ea() {
      let myChart = this.$refs.historical;
      this.historical = echarts.init(myChart);
      this.historical.setOption(this.option2);
    },
    // 体质健康分析
    handleInitBox1() {
      let myChart = this.$refs.box_1;
      this.box1 = echarts.init(myChart);
      this.box1.setOption(this.option3);
    },
  },
};
</script>
<style lang="less" scoped>
.t-c {
  color: #ccc !important;
}
.min-w900 {
  min-width: 900px;
}
.box1 {
  margin-bottom: 26px;
  margin-top: 21px;
  align-items: flex-start;
  .box1_left {
    width: 250px;
    height: 302px;
    background: #f1f3fd;
    border-radius: 10px 10px 10px 10px;
    padding: 15px 17px;
    box-sizing: border-box;
    margin-right: 10px;
    .box1_header {
      .name1 {
        font-size: 40px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #101010;
        width: 80px;
        height: 80px;
        border-radius: 0px 0px 0px 0px;
        border: 1px dashed #5087ec;
        box-sizing: border-box;
        text-align: center;
        line-height: 80px;
        margin-right: 4px;
      }
      .name2 {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #101010;
        white-space: nowrap;
      }
    }
    .box1_main {
      font-size: 40px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #101010;
      text-align: center;
    }
    .box1_footer {
      .box1_bg {
        position: relative;
        .hg {
          position: absolute;
          font-size: 26px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #5087ec;
          text-align: center;
          width: 100%;
        }
        .zpf {
          text-align: center;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #101010;
        }
        img {
          width: 180px;
          height: 91px;
          margin: 0 auto;
        }
      }
    }
  }
  .box1_right {
    flex: 1;
    flex-wrap: wrap;
    .prj {
      width: 24%;
      height: 146px;
      background: #f1f3fd;
      border-radius: 10px 10px 10px 10px;
      padding-right: 10px;
      box-sizing: border-box;
      margin-right: 10px;
      margin-bottom: 10px;
      &:nth-child(4n) {
        margin-right: 0px;
        margin-bottom: 0px;
      }
      .prj_header {
        .status {
          width: 74px;
          height: 73px;
          background-image: url("../../assets//img/128.png");
          background-size: 100% 100%;

          box-sizing: border-box;
        }
        .status1 {
          background-image: url("../../assets//img/129.png");
        }
        .status2 {
          background-image: url("../../assets//img/130.png");
        }
        .status3 {
          background-image: url("../../assets//img/128.png");
        }
        .status4 {
          background-image: url("../../assets//img/131.png");
          .status_title {
            margin-top: 6px;
          }
        }
        .status_title {
          transform: rotate(312deg);
          margin-right: 5px;
          font-size: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
        .prj_name {
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #101010;
          text-align: end;
        }
        .prj_dw {
          font-size: 15px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #101010;
          text-align: end;
        }
      }
      .prj_img {
        width: 32px;
        height: 42px;
        margin-right: 7px;
      }
      .prj_img1 {
        width: 34px;
        height: 31px;
      }
      .prj_img3 {
        width: 34px;
        height: 24px;
      }
      .prj_st {
        font-size: 28px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #101010;
        margin-top: 18px;
        padding-left: 10px;
        box-sizing: border-box;
      }
    }
    .prj1 {
      margin-top: 10px;
    }
  }
}

.box2 {
  margin-top: 22px;
  margin-bottom: 26px;
  align-items: flex-start;
  width: 100%;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #baccfb;
  box-sizing: border-box;
  padding: 28px 26px;
  box-sizing: border-box;
  .box2_left {
    width: 60%;
    font-size: 19px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #101010;
  }
  .cont_box2 {
    margin-bottom: 10px;
  }
  .box2_right {
    flex: 1;
    height: 300px;
  }
}
.box3 {
  width: 100%;
  min-height: 785px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #baccfb;
  box-sizing: border-box;
  margin-top: 20px;
  flex-wrap: wrap;
  display: flex;

  .box3_item {
    width: 32.5%;
    // height: 50%;
    height: 392px;
    box-sizing: border-box;
  }
}
.tip_jy {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-top: 10px;
}
.box222 {
  // border: 1px solid;
  width: 100%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}
.box_1 {
  width: 250px;
  height: 160px;
}
.header_box {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #226bff;
  align-items: center;
  // border: 1px solid;
  box-sizing: border-box;
  margin-bottom: 28px;
  .cl_ti {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #202020;
  }
  .hader_2 {
    .hader_2_title {
      font-size: 20px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #101010;
    }
    .sel_t {
      width: 120px;
      // height: 35px;
      border-radius: 5px 5px 5px 5px;
      // border: 1px solid #dfe5ec;
      box-sizing: border-box;
    }
  }
}
.header {
  cursor: pointer;
  margin-right: 25px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  // margin-top: 38px;

  img {
    width: 23px;
    height: 23px;
    margin-right: 15px;
  }
}
.main {
  margin-top: 10px;
  align-items: flex-start;
  .left {
    width: 279px;
    // height: 625px;
    // height: 671px;
    // height: 1760px;
    height: 100%;
    background: #f1f3fd;
    border-radius: 15px;
    // margin-right: 52px;
    margin-right: 12px;
    padding: 18px 18px;
    box-sizing: border-box;
    /deep/.el-tabs__nav-wrap::after {
      border: 0;
    }

    /deep/.el-tabs__active-bar {
      background: #101010;
    }
    /deep/.el-tabs__item {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #777777;
    }
    /deep/ .is-active {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #101010;
    }
    .tiitle {
      margin-bottom: 15px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
    }
    .left_item {
      flex-wrap: wrap;
      // height: 590px;
      // height: 1639px;
      min-height: 400px;
      overflow: auto;
      display: flex;
      align-content: flex-start;
      justify-content: space-between;
    }
    .list_item {
      width: 116px;
      height: 40px;
      background: #ffffff;
      text-align: center;
      line-height: 40px;
      border-radius: 10px;
      margin-bottom: 10px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    .active {
      color: #ffffff;
      background: #226bff;
    }
  }

  .right {
    flex: 1;
    width: calc(100% - 290px);
    .right_header {
      .right_header_item {
        width: 143px;
        height: 43px;
        text-align: center;
        line-height: 43px;
        border-radius: 22px 22px 22px 22px;
        font-size: 24px;
        color: #101010;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        cursor: pointer;
      }
      .active_header {
        background: #5087ec;
        color: #ffffff;
      }
    }
    .right_main {
      // height: 1702px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #baccfb;
      margin-top: 15px;
      padding: 24px 20px;
      width: 100%;
      box-sizing: border-box;
      .right_title {
        font-size: 24px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #101010;
        margin-right: 33px;
      }
      .img_list {
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5087ec;
        cursor: pointer;
        img {
          width: 23px;
          height: 23px;
          margin-right: 9px;
        }
        .img1_1 {
          width: 21px;
          height: 24px;
        }
      }

      .performance {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.circleBox {
  position: relative;
  text-align: center;
  width: 170px;
  height: 170px;
  margin: 20px 0;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202020;
  .circleCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    div {
      font-size: 26px;
      color: #1360fb;
      font-weight: 600;
      margin-bottom: 5px;
    }
    span {
      font-size: 14px;
      color: #999999;
    }
  }
  .textCenter {
    position: absolute;
    left: 50%;
    top: 35%;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    transform: translate(-50%);
    .score {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #444444;
    }
  }
}

.ml-20 {
  margin-left: 20px;
}

.align-center {
  display: flex;
  align-items: center;
}

.icon_img {
  width: 20px;
  height: 23px;
  margin-right: 4px;

  img {
    width: 100%;
    height: 100%;
  }
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}
.historical_select_time {
  // padding: 0 27px;
  width: 100%;

  .select_time_link {
    width: 100%;
    padding: 18px 0 17px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #3f3f3f;
    border-bottom: 1px solid #dedede;
    white-space: nowrap;
    justify-content: center;
    cursor: pointer;

    &:nth-last-child(1) {
      border-bottom-width: 0px;
    }
  }

  .select_time_active {
    color: #5087ec;
  }
}

.wh100 {
  width: 100%;
  height: 100%;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
