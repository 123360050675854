export const Grade_List = [
  { label: "一年级", value: 1 },
  { label: "二年级", value: 2 },
  { label: "三年级", value: 3 },
  { label: "四年级", value: 4 },
  { label: "五年级", value: 5 },
  { label: "六年级", value: 6 },
  { label: "七年级", value: 7 },
  { label: "八年级", value: 8 },
  { label: "九年级", value: 9 },
  { label: "高一", value: 10 },
  { label: "高二", value: 11 },
  { label: "高三", value: 12 },
];
export const range = {
  tooltip: {
    trigger: "item",

    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  legend: {
    bottom: "3%",
    left: "center",
    // formatter: function (name) {
    //   // 在这里编写自定义的格式化逻辑
    //   let value;
    //   for (let i = 0; i < sex_ratioData.length; i++) {
    //     // value = grade_ratioData[i].value;
    //     // console.log(i, name);
    //     if (name == sex_ratioData[i].name) {
    //       return name + "(" + sex_ratioData[i].value + "%" + ")";
    //     }
    //   }
    // },
  },
  series: [
    {
      name: "优秀率",
      type: "pie",
      center: ["50%", "48%"],
      radius: "70%",
      avoidLabelOverlap: false,
      label: {
        show: true,
        position: "center",
        fontSize: 24,
        formatter: function (name) {
          // 在这里编写自定义的格式化逻辑
          let value;
          return "10%";
          // for (let i = 0; i < big_breakday.length; i++) {
          //   // value = grade_ratioData[i].value;
          //   if (
          //     name.name == big_breakday[i].name &&
          //     big_breakdayCount[i] != 0
          //   ) {
          //     // return classname == "courseDay" || classname == "bigBreakDay"
          //     //   ? big_breakdayCount[i]
          //     //   :
          //     return big_breakday[i].value + "%";
          //   } else if (
          //     name.name == big_breakday[i].name &&
          //     big_breakdayCount[i] == 0
          //   ) {
          //     return "";
          //   }
          // }
        },
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      color: ["#B3D9F3", "#67B3E8"],
      labelLine: {
        show: false,
      },
      data: [{ value: 0, name: "优秀率" }],
    },
  ],
};
export const Overall_Box_Pie_Option = {
  tooltip: {
    trigger: "item",
  },
  legend: {
    bottom: "5%",
    left: "center",
    textStyle: {
      color: "#444444",
      fontSize: 18,
    },
  },
  graphic: [
    {
      //环形图中间添加文字
      type: "text", //通过不同top值可以设置上下显示
      left: "center",
      top: "31%",
      style: {
        text: "全区",
        textAlign: "center",
        fill: "#101010", //文字的颜色
        fontSize: 24,
        lineHeight: 24,
      },
    },
    {
      //环形图中间添加文字
      type: "text", //通过不同top值可以设置上下显示
      left: "center",
      top: "40%",
      style: {
        text: "整体情况",
        textAlign: "center",
        fill: "#101010", //文字的颜色
        fontSize: 24,
        lineHeight: 24,
      },
    },
  ],
  series: [
    {
      name: "",
      type: "pie",
      radius: ["35%", "60%"],
      center: ["50%", "40%"],
      itemStyle: {
        borderRadius: 0,
        borderColor: "#fff",
        borderWidth: 2,
      },
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 18,
          lineHeight: 24,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
        overflow: "none",
      },
      labelLine: {
        show: true,
        length: 18,
        length2: 20,
      },
      color: ["#4388D6", "#F1C344", "#F25757"],
      data: [
        { value: 0, name: "优秀率" },
        { value: 0, name: "合格率" },
        { value: 0, name: "不及格率" },
      ],
    },
  ],
};

export const Overall_Box_Bar_Option = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  legend: {},
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    top: "10%",
    containLabel: true,
  },
  xAxis: {
    type: "value",
    axisLabel: {
      color: "#444",
      fontSize: 18,
      formatter: function (value, index) {
        return value + "%";
      },
    },
    max: 100,
  },
  yAxis: {
    type: "category",
    axisLabel: {
      color: "#444",
      fontSize: 18,
    },
    data: [
      "一年级",
      "二年级",
      "三年级",
      "四年级",
      "五年级",
      "六年级",
      "七年级",
      "八年级",
      "九年级",
    ],
  },
  series: [
    {
      name: "优秀率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        textStyle: {
          color: "#fff",
          fontSize: 16,
        },
        formatter: function (record) {
          return record.value + "%";
        },
      },
      barWidth: 21,
      emphasis: {
        focus: "series",
      },
      data: [],
    },
    {
      name: "良好率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        textStyle: {
          color: "#fff",
          fontSize: 16,
        },
        formatter: function (record) {
          return record.value + "%";
        },
      },
      barWidth: 21,
      emphasis: {
        focus: "series",
      },
      data: [],
    },
    {
      name: "及格率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        textStyle: {
          color: "#fff",
          fontSize: 16,
        },
        formatter: function (record) {
          return record.value + "%";
        },
      },
      barWidth: 21,
      emphasis: {
        focus: "series",
      },
      data: [],
    },
    {
      name: "不合格率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        textStyle: {
          color: "#fff",
          fontSize: 16,
        },
        formatter: function (record) {
          return record.value + "%";
        },
      },
      barWidth: 21,
      emphasis: {
        focus: "series",
      },
      data: [],
    },
  ],
};

function inr(i) {
  return 75 - 2.5 * i * 10 + "%";
}
function outr(i) {
  return 90 - 2.5 * i * 10 + "%";
}

function array2obj(array, key) {
  var resObj = {};
  for (var i = 0; i < array.length; i++) {
    resObj[array[i][key]] = array[i];
  }
  return resObj;
}

var data2 = [
  {
    name: "合格率",
    value: 0,
  },
  {
    name: "优秀率",
    value: 0,
  },
];
var sumValue = 133.33;
var objData = array2obj(data2, "name");

function getData(data) {
  var res = {
    series: [],
    yAxis: [],
  };
  for (let i = 0; i < data.length; i++) {
    res.series.push({
      name: "",
      type: "pie",
      clockWise: false, //顺时加载
      hoverAnimation: false, //鼠标移入变大
      radius: [inr(i), outr(i)],
      center: ["50%", "50%"],
      label: {
        show: false,
      },
      itemStyle: {
        borderRadius: 100,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        borderWidth: 5,
      },
      color: i == 0 ? "#52B89D" : "#2185B2",
      data: [
        {
          value: data2[i].value,
          name: data2[i].name,
        },
        {
          value: sumValue - data2[i].value,
          name: "",
          itemStyle: {
            color: "rgba(0,0,0,0)",
            borderWidth: 0,
          },
          tooltip: {
            show: false,
          },
          hoverAnimation: false,
        },
      ],
    });
    res.series.push({
      name: "",
      type: "pie",
      silent: true,
      z: 1,
      clockWise: false, //顺时加载
      hoverAnimation: false, //鼠标移入变大
      radius: [inr(i), outr(i)],
      center: ["50%", "50%"],
      label: {
        show: false,
      },
      itemStyle: {
        borderRadius: 100,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        borderWidth: 5,
      },
      data: [
        {
          value: 7.5,
          itemStyle: {
            color: i == 0 ? "#A8DBCE" : "#8BD0DB",
            borderWidth: 0,
          },
          tooltip: {
            show: false,
          },
          hoverAnimation: false,
        },
        {
          value: 2.5,
          name: "",
          itemStyle: {
            color: "rgba(0,0,0,0)",
            borderWidth: 0,
          },
          tooltip: {
            show: false,
          },
          hoverAnimation: false,
        },
      ],
    });
  }
  return res;
}

var optionData = getData(data2);

export const Overall_Multple_Pie_Option = {
  backgroundColor: "#fff",
  grid: {
    top: "0%",
    left: "0%",
    right: "0%",
    bottom: "0%",
  },
  legend: {
    show: true,
    icon: "none",
    top: "6%",
    left: "44%",
    data: ["合格率", "优秀率"],
    padding: [0, 16],
    itemGap: 28,

    formatter: function (name) {
      if (name == "合格率") {
        return "{title1|" + name + "} {value1|" + objData[name].value + "%}";
      } else {
        return "{title2|" + name + "} {value2|" + objData[name].value + "%}";
      }
    },

    textStyle: {
      rich: {
        title1: {
          fontSize: 20,
          lineHeight: 20,
          color: "#52B89D",
          fontWeight: "normal",
          align: "right",
        },
        value1: {
          fontSize: 20,
          lineHeight: 40,
          color: "#52B89D",
          align: "right",
        },
        title2: {
          fontSize: 20,
          lineHeight: 20,
          color: "#2185B2",
          fontWeight: "normal",
          align: "right",
        },
        value2: {
          fontSize: 20,
          lineHeight: 40,
          color: "#2185B2",
          align: "right",
        },
      },
    },
  },
  tooltip: {
    show: true,
    trigger: "item",
    formatter: "{b}:{c}%",
  },
  xAxis: [
    {
      show: false,
    },
  ],
  series: optionData.series,
};

export const Sports_Events_Enum = [
  {
    id: 1,
    name: "BMI",
    unit: "千克/米²",
    type: "BMI",
    key: "student_BMI",
    scoreKey: "student_BMI_score",
    value: 0,
    score: 0,
    icon: require("@/assets/img/115.png"),
    iconStyle: "width: 31px; height: 41px",
  },
  {
    id: 2,
    name: "肺活量",
    key: "student_lung",
    type: "LUNG",
    scoreKey: "student_lung_score",
    unit: "毫升",
    value: 0,
    score: 0,
    icon: require("@/assets/img/116.png"),
    iconStyle: "width: 33px; height: 30px",
  },
  {
    id: 3,
    name: "50米跑",
    unit: "秒",
    type: "RUNNING_50METERS",
    key: "student_50meters",
    scoreKey: "student_50meters_score",
    value: 0,
    score: 0,
    icon: require("@/assets/img/117.png"),
    iconStyle: "width: 31px; height: 35px",
  },
  {
    id: 4,
    name: "坐位体前屈",
    unit: "厘米",
    key: "student_stretch",
    type: "STRETCH",
    scoreKey: "student_stretch_score",
    value: 0,
    score: 0,
    icon: require("@/assets/img/118.png"),
    iconStyle: "width: 34px; height: 30px",
  },
  {
    id: 5,
    name: "1分钟跳绳",
    unit: "次",
    type: "JUMP",
    key: "student_1min_rope",
    scoreKey: "student_1min_rope_score",
    value: 0,
    score: 0,
    icon: require("@/assets/img/119.png"),
    iconStyle: "width: 28px; height: 38px",
  },
  {
    id: 6,
    name: "1分钟仰卧起坐",
    unit: "次",
    key: "student_1min_sit_up",
    type: "SIT_UP",
    scoreKey: "student_1min_sit_up_score",
    value: 0,
    score: 0,
    icon: require("@/assets/img/120.png"),
    iconStyle: "width: 34px; height: 24px",
  },
  {
    id: 7,
    name: "50米×8往返跑",
    unit: "分·秒",
    key: "student_50meters_x8",
    type: "RUNNING_50METERS_X8",
    scoreKey: "student_50meters_x8_score",
    value: 0,
    score: 0,
    icon: require("@/assets/img/117.png"),
    iconStyle: "width: 31px; height: 35px",
  },
  {
    id: 8,
    name: "立定跳远",
    unit: "厘米",
    key: "student_standing_jump",
    type: "STANDING_JUMP",
    scoreKey: "student_standing_jump_score",
    value: 0,
    score: 0,
    icon: require("@/assets/img/121.png"),
    iconStyle: "width: 29px; height: 31px",
  },
  {
    id: 9,
    name: "引体向上(男)",
    unit: "个",
    key: "student_pull_up",
    type: "PULL_UP",
    scoreKey: "student_pull_up_score",
    value: 0,
    score: 0,
    icon: require("@/assets/img/122.png"),
    iconStyle: "width: 29px; height: 31px",
  },
  {
    id: 10,
    name: "1000米跑(男)",
    unit: "秒",
    type: "RUNNING_1000METERS",
    key: "student_1000meters",
    scoreKey: "student_1000meters_score",
    value: 0,
    score: 0,
    icon: require("@/assets/img/117.png"),
    iconStyle: "width: 31px; height: 35px",
  },
  {
    id: 11,
    name: "800米跑(女)",
    unit: "秒",
    type: "RUNNING_800METERS",
    key: "student_800meters",
    scoreKey: "student_800meters_score",
    value: 0,
    score: 0,
    icon: require("@/assets/img/117.png"),
    iconStyle: "width: 31px; height: 35px",
  },
];

export const Test_Conclusion_Echart = {
  tooltip: {
    trigger: "item",
  },
  radar: {
    indicator: [
      { name: "BMI", max: 100, min: 0 },
      { name: "坐位体前屈", max: 100, min: 0 },
      { name: "1分钟跳绳", max: 100, min: 0 },
      { name: "1分钟仰卧起坐", max: 100, min: 0 },
      { name: "50米跑", max: 100, min: 0 },
      { name: "肺活量", max: 100, min: 0 },
    ],
    axisName: {
      color: "#5087EC",
      fontSize: 19,
    },
    splitArea: {
      areaStyle: {
        color: ["#F7FCFF"],
      },
    },
    splitLine: {
      lineStyle: {
        color: ["#E2E3E4"],
      },
    },
    axisLine: {
      show: false,
    },
  },
  series: [
    {
      name: "测试结论",
      type: "radar",
      color: "#5087EC",
      data: [
        {
          value: [80, 73, 98, 97, 95, 92],
          name: "",
        },
      ],
    },
  ],
};

export const Subproject_Rate_Value_Echart = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  legend: {},
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: [],
    axisLabel: {
      color: "#444444",
      fontSize: "14",
      interval: 0,
    },
  },
  yAxis: {
    type: "value",
    axisLabel: {
      color: "#444444",
      fontSize: "16",
      formatter: function (value, index) {
        return value + "%";
      },
    },
    max: 100,
  },
  color: ["#4388D6", "#3AAA2E", "#F1C344", "#F25757"],
  series: [
    {
      name: "优秀率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        fontSize: 16,
        formatter: function (record) {
          return record.value + "%";
        },
      },
      emphasis: {
        focus: "series",
      },
      data: [],
    },
    {
      name: "良好率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        fontSize: 16,
        formatter: function (record) {
          return record.value + "%";
        },
      },
      emphasis: {
        focus: "series",
      },
      data: [],
    },
    {
      name: "及格率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        fontSize: 16,
        formatter: function (record) {
          return record.value + "%";
        },
      },
      emphasis: {
        focus: "series",
      },
      data: [],
    },
    {
      name: "不及格率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        fontSize: 16,
        formatter: function (record) {
          return record.value + "%";
        },
      },
      emphasis: {
        focus: "series",
      },
      data: [],
    },
  ],
};

export const physical_Health_Trends_Echart = {
  title: {
    text: "BMI",
    left: "center", // 标题居中
    bottom: 0, // 距离上边框20像素
  },
  xAxis: {
    type: "category",
    data: [],
    axisLabel: {
      formatter: (value) => {
        return value.split(` `).join(`\n`); // return this.formatXlabel (value)
      },
      rotate: 45,
      color: "#444444",
      fontSize: "14",
    },
  },
  yAxis: {
    type: "value",
    axisLabel: {
      color: "#444444",
      fontSize: "14",
    },
    max: 100,
  },
  grid: {
    bottom: "28%",
    top: "10%",
    left: "15%",
    right: "5%",
  },
  tooltip: {
    formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
  },
  series: [
    {
      data: [80, 90],
      type: "bar",
      color: "#5087EC",
      barMaxWidth: 30,
    },
  ],
};

export const School_Excellence_Trends_Echart = {
  title: {
    text: "优秀率",
    left: "center",
    top: "2%",
    textStyle: {
      fontSize: 28,
    },
  },
  grid: {
    left: "16%",
    right: "5%",
    bottom: "5%",
    top: "10%",
    containLabel: true,
  },
  tooltip: {
    trigger: "item",
  },
  legend: {
    orient: "horizontal",
    left: "center",
  },
  xAxis: {
    type: "value",
    axisLabel: {
      formatter: "{value} %",
      fontSize: 14,
    },
  },
  yAxis: {
    type: "category",
    data: [],
    axisLabel: {
      fontSize: 16,
      color: "#333",
    },
    axisLine: {
      lineStyle: {
        width: 2,
      },
    },
    axisTick: {
      lineStyle: {
        width: 2,
      },
    },
  },
  color: ["#488CD1"],
  series: [
    {
      data: [],
      type: "bar",
      barGap: 0,
      label: {
        show: true,
        color: "#20a9ff",
        fontSize: 14,
        formatter: function (record) {
          return record.value + "%";
        },
        position: "right",
      },
    },
  ],
};

export const School_Qualified_Trends_Echart = {
  title: {
    text: "合格率",
    left: "center",
    top: "2%",
    textStyle: {
      fontSize: 28,
    },
  },
  grid: {
    left: "16%",
    right: "5%",
    bottom: "5%",
    top: "10%",
    containLabel: true,
  },
  tooltip: {
    trigger: "item",
  },
  legend: {
    orient: "horizontal",
    left: "center",
  },
  xAxis: {
    type: "value",
    axisLabel: {
      formatter: "{value} %",
      fontSize: 14,
    },
  },
  yAxis: {
    type: "category",
    data: [
      "总体",
      "湘仪学校",
      "周南梅溪湖小学",
      "博才卓越小学",
      "博才白鹤小学",
      "望月湖第二小学",
      "德润园小学",
      "博才洋湖小学",
      "市实验小学梅溪湖学校",
      "岳麓区实验小学西校区",
      "枫林绿洲小学",
      "白马实验小学",
      "岳麓区实验小学东校区",
      "岳麓区第一小学",
    ],
    axisLabel: {
      fontSize: 16,
      color: "#333",
    },
    axisLine: {
      lineStyle: {
        width: 2,
      },
    },
    axisTick: {
      lineStyle: {
        width: 2,
      },
    },
  },
  color: ["#488CD1"],
  series: [
    {
      data: [],
      type: "bar",
      barGap: 0,
      label: {
        show: true,
        color: "#20a9ff",
        fontSize: 14,
        formatter: function (record) {
          return record.value + "%";
        },
        position: "right",
      },
    },
  ],
};
