<template>
  <div>
    <div class="flexSb">
      <div class="flexSt">
        <div class="right_title">个人体测成绩</div>
        <!-- <div class="flexSt img_list">
                    <img
                      class="img1_1"
                      src="@/assets/img/128.png"
                      alt=""
                    />
                    <div>报告</div>
                  </div> -->

        <div class="flexSt img_list ml-20">
          体测日期：{{ school_record_time ? school_record_time : "---" }}
        </div>
      </div>
      <!-- <div class="flexSt img_list">
                  <img src="../@/assets/img/129.png" alt="" />
                  <div>历史体测</div>
                </div> -->
    </div>

    <div class="box1 flexSb">
      <div class="box1_left">
        <div class="box1_header flexSb">
          <div class="name1">
            <canvas type="2d" ref="nameCanvasRef" class="wb100"></canvas>
          </div>
          <div>
            <div class="name2">
              姓名：{{
                curentStudent && curentStudent.name ? curentStudent.name : "---"
              }}
            </div>
            <div class="name2">
              性别：
              {{
                curentStudent
                  ? curentStudent.gender == 0
                    ? "女"
                    : "男"
                  : "---"
              }}
            </div>
            <div class="name2">
              编号：{{
                curentStudent && curentStudent.id ? curentStudent.id : "---"
              }}
            </div>
            <div class="name2">
              班级：{{ levelName || "---" }}
              {{
                curentStudent && curentStudent.class_name
                  ? curentStudent.class_name
                  : "---"
              }}
            </div>
          </div>
        </div>
        <div class="box1_main">
          <span v-if="student_data && student_data.total_score"
            >{{ student_data.total_score }}分</span
          ><span v-else>---</span>
        </div>
        <div class="box1_footer">
          <div class="box1_bg">
            <div class="hg">
              {{
                student_data && student_data.total_score_level_name
                  ? student_data.total_score_level_name
                  : "---"
              }}
            </div>
            <img src="@/assets/img/114.png" alt="" />
            <div class="zpf">总评分</div>
          </div>
        </div>
      </div>
      <div class="box1_right align-center">
        <template
          v-if="physicalHealthIndicator && physicalHealthIndicator.length"
        >
          <div
            class="prj"
            v-for="(item, index) in physicalHealthIndicator"
            :key="index"
          >
            <div class="prj_header flexSb">
              <div
                class="status"
                :class="`status${item.levelNum ? item.levelNum : 3}`"
              >
                <div class="status_title">
                  {{ item.levelName ? item.levelName : "---" }}
                </div>
              </div>
              <div>
                <div class="prj_name">{{ item.name }}</div>
                <div class="prj_dw">（{{ item.unit }}）</div>
              </div>
            </div>
            <div class="flexSb prj_st">
              <div class="flexSt">
                <img class="prj_img" :src="item.icon" alt="" />
                <div>
                  {{ item.score || item.score == 0 ? `${item.score}分` : "---" }}
                </div>
              </div>
              <div>
                {{ item.value || item.value == 0 ? item.value : "---" }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="wh100 center min-w900">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </template>
      </div>
    </div>
    <div class="right_title">测试结论</div>
    <div class="box2 flexSb">
      <div
        class="box2_left"
        v-if="
          personal_total_report_str && personal_part_str && personal_compare_str
        "
      >
        <div class="cont_box2" v-if="personal_total_report_str">
          1.{{ personal_total_report_str }}
        </div>
        <div class="cont_box2" v-if="personal_part_str">
          2.{{ personal_part_str }}
        </div>
        <div v-if="personal_compare_str">3.{{ personal_compare_str }}</div>
      </div>
      <div class="box2_left" v-else>
        <div class="wh100 center min-w900">
          <el-empty description="暂无数据"></el-empty>
        </div>
      </div>
      <div class="box2_right" ref="dialog_box_1"></div>
    </div>
    <div class="right_title">体质健康趋势</div>

    <div class="box3 wh100">
      <template v-if="trendBarList && trendBarList.length">
        <div
          class="box3_item"
          v-for="(item, index) in trendBarList"
          :key="index"
          ref="trendDialogBarRef"
        ></div>
      </template>
      <template v-else>
        <div class="wh100 center min-w1400">
          <el-empty description="暂无数据"></el-empty>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    isReportDialog: {
      type: Boolean,
      default: false,
    },
    school_record_time: {
      type: String,
      default: "",
    },
    physicalHealthIndicator: {
      type: Array,
      default: [],
    },
    trendBarList: {
      type: Array,
      default: [],
    },
    levelName: {
      type: String,
      default: "",
    },
    personal_total_report_str: {
      type: String,
      default: "",
    },
    personal_part_str: {
      type: String,
      default: "",
    },
    personal_compare_str: {
      type: String,
      default: "",
    },
    student_data: {
      type: Object,
      default: {},
    },
    compare_data: {
      type: Object,
      default: {},
    },
    curentStudent: {
      type: Object,
      default: {},
    },
    echartsOption: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      box1: null,
      dialog_box3_1: null,
      dialog_box3_2: null,
      dialog_box3_3: null,
      dialog_box3_4: null,
      dialog_box3_5: null,
      dialog_box3_6: null,
    };
  },
  computed: {
    studentName() {
      const { curentStudent } = this;
      return curentStudent && curentStudent.name
        ? curentStudent.name.substr(curentStudent.name.length - 1)
        : "---";
    },
  },
  created() {
    this.trendBarInit();
  },
  mounted() {
    this.drawCanvas1();
    this.handleInitBox1();
    this.setTrendBar();
    this.WatchResize();
  },
  watch: {
    isReportDialog(value) {
      if (value) {
        this.drawCanvas1();
        this.handleInitBox1();
        this.setTrendBar();
      }
    },
  },
  methods: {
    isCondition(key1, key2) {
      const { student_data } = this;
      const StringCondition = ["null", "undefined"];
      return (
        student_data &&
        (student_data[key1] ||
          StringCondition.includes(JSON.stringify(student_data[key1])) ||
          student_data[key2] ||
          StringCondition.includes(JSON.stringify(student_data[key2])))
      );
    },

    trendBarInit() {
      this.trendBarList.forEach((item, index) => {
        if (!this[`trendBar${index + 1}`]) {
          this[`trendBar${index + 1}`] = null;
        }
      });
    },

    // 趋势图初始化
    setTrendBar() {
      this.$nextTick(() => {
        const trendBarRefs = this.$refs.trendDialogBarRef;
        this.trendBarList.forEach((item, index) => {
          this[`trendBar${index + 1}`] = echarts.init(trendBarRefs[index]);
          this[`trendBar${index + 1}`].setOption(item);
        });
      });
    },

    drawCanvas1() {
      const { curentStudent } = this;
      const studentName =
        curentStudent && curentStudent.name
          ? curentStudent.name.substr(curentStudent.name.length - 1)
          : "---";
      const canvas = this.$refs.nameCanvasRef;
      canvas.width = 78;
      canvas.height = 78;
      const canvasCxt = canvas.getContext("2d");
      canvasCxt.font = "40px Microsoft YaHei-Regular, Microsoft YaHei";
      canvasCxt.fillStyle = "#101010";
      canvasCxt.fillText(studentName, 18, 54);
    },
    // 对比
    contrastData(record1, record2) {
      if (record1 < record2) {
        return "低于";
      } else {
        return "高于";
      }
    },

    // 历史健康分析
    handleInitclass_ea() {
      let myChart = this.$refs.historical;
      this.historical = echarts.init(myChart);
      this.historical.setOption(this.echartsOption.option2);
    },

    // 体质健康分析
    handleInitBox1() {
      let myChart = this.$refs.dialog_box_1;
      this.box1 = echarts.init(myChart);
      this.box1.setOption(this.echartsOption.option3);
    },

    WatchResize() {
      const that = this;
      window.addEventListener("resize", function () {
        that.box1.resize();
        that.$refs.trendDialogBarRef.forEach((item, index) => {
          that[`trendBar${index + 1}`].resize();
        });
      });
    },
  },
};
</script>

<style lang='less' scoped>
.min-w900 {
  min-width: 900px;
}
.wb100 {
  width: 100%;
  height: 100%;
}
.row-center {
  justify-content: center;
  display: flex;
}
.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 2068;
  background-color: rgba(0, 0, 0, 0.5);
}

img {
  width: 100%;
  height: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog_title {
  width: 100%;
  height: 127px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  background-color: #fff;
}
.dialog_contain {
  margin-left: 40px;
  margin-right: 40px;
  padding: 26px 20px 20px 30px;
  border-radius: 10px;
  border: 1px solid #baccfb;
  box-sizing: border-box;
  background-color: #fff;
}
.right_title {
  font-size: 24px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #101010;
  margin-right: 33px;
}
.img_list {
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #5087ec;
  height: 30px;
  cursor: pointer;
  img {
    width: 23px;
    height: 23px;
    margin-right: 9px;
  }
  .img1_1 {
    width: 21px;
    vertical-align: middle;
    height: 24px;
  }
}

.performance {
  width: 100%;
  height: 100%;
}

.box1 {
  margin-bottom: 26px;
  margin-top: 21px;
  align-items: flex-start;
  .box1_left {
    width: 250px;
    height: 302px;
    background: #f1f3fd;
    border-radius: 10px 10px 10px 10px;
    padding: 15px 17px;
    box-sizing: border-box;
    margin-right: 10px;
    .box1_header {
      .name1 {
        font-size: 40px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #101010;
        width: 80px;
        height: 80px;
        border-radius: 0px 0px 0px 0px;
        border: 1px dashed #5087ec;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
      }
      .name2 {
        padding-left: 4px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #101010;
        white-space: nowrap;
      }
    }
    .box1_main {
      font-size: 40px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #101010;
      text-align: center;
    }
    .box1_footer {
      .box1_bg {
        position: relative;
        .hg {
          position: absolute;
          font-size: 26px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #5087ec;
          text-align: center;
          width: 100%;
        }
        .zpf {
          text-align: center;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #101010;
        }
        img {
          width: 180px;
          height: 91px;
          margin: 0 auto;
        }
      }
    }
  }
  .box1_right {
    flex: 1;
    flex-wrap: wrap;
    .prj {
      width: 24%;
      height: 146px;
      background: #f1f3fd;
      border-radius: 10px 10px 10px 10px;
      padding-right: 10px;
      box-sizing: border-box;
      margin-right: 10px;
      margin-bottom: 10px;
      &:nth-child(4n) {
        margin-right: 0px;
        margin-bottom: 0px;
      }
      .prj_header {
        .status {
          width: 74px;
          height: 73px;
          background-image: url("../../../../assets/img/128.png");
          background-size: 100% 100%;
          box-sizing: border-box;
          position: relative;
        }
        .status1 {
          background-image: url("../../../../assets/img/129.png");
        }
        .status2 {
          background-image: url("../../../../assets/img/130.png");
        }
        .status3 {
          background-image: url("../../../../assets/img/128.png");
        }
        .status4 {
          background-image: url("../../../../assets/img/131.png");
        }
        .status_title {
          transform: rotate(-48deg);
          font-size: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          white-space: nowrap;
        }
        .prj_name {
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #101010;
          text-align: end;
        }
        .prj_dw {
          font-size: 15px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #101010;
          text-align: end;
        }
      }
      .prj_img {
        width: 32px;
        height: 42px;
        margin-right: 7px;
      }
      .prj_img1 {
        width: 34px;
        height: 31px;
      }
      .prj_img3 {
        width: 34px;
        height: 24px;
      }
      .prj_st {
        font-size: 28px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #101010;
        margin-top: 18px;
        padding-left: 10px;
        box-sizing: border-box;
      }
    }
    .prj1 {
      margin-top: 10px;
    }
  }
}

.box2 {
  margin-top: 22px;
  margin-bottom: 26px;
  align-items: flex-start;
  width: 100%;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #baccfb;
  box-sizing: border-box;
  padding: 28px 26px;
  box-sizing: border-box;
  .box2_left {
    width: 60%;
    font-size: 19px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #101010;
  }
  .cont_box2 {
    margin-bottom: 10px;
  }
  .box2_right {
    height: 300px;
    width: 540px;
  }
}
.box3 {
  width: 100%;
  height: 785px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #baccfb;
  box-sizing: border-box;
  margin-top: 20px;
  flex-wrap: wrap;
  display: flex;
  .box3_item {
    width: 32.5%;
    height: 50%;
    box-sizing: border-box;
  }
}
.tip_jy {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-top: 10px;
}
.box222 {
  // border: 1px solid;
  width: 100%;
  position: relative;
}
.dialog_box_1 {
  width: 250px;
  height: 160px;
}
.header_box {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #226bff;
  align-items: center;
  // border: 1px solid;
  box-sizing: border-box;
  margin-bottom: 28px;
  .cl_ti {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #202020;
  }
  .hader_2 {
    .hader_2_title {
      font-size: 20px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #101010;
    }
    .sel_t {
      width: 120px;
      // height: 35px;
      border-radius: 5px 5px 5px 5px;
      // border: 1px solid #dfe5ec;
      box-sizing: border-box;
    }
  }
}
.header {
  cursor: pointer;
  margin-right: 25px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  // margin-top: 38px;

  img {
    width: 23px;
    height: 23px;
    margin-right: 15px;
  }
}
.main {
  margin-top: 10px;
  align-items: flex-start;
  .left {
    width: 279px;
    // height: 625px;
    // height: 671px;
    // height: 1760px;
    height: 100%;
    background: #f1f3fd;
    border-radius: 15px;
    // margin-right: 52px;
    margin-right: 12px;
    padding: 18px 18px;
    box-sizing: border-box;
    /deep/.el-tabs__nav-wrap::after {
      border: 0;
    }

    /deep/.el-tabs__active-bar {
      background: #101010;
    }
    /deep/.el-tabs__item {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #777777;
    }
    /deep/ .is-active {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #101010;
    }
    .tiitle {
      margin-bottom: 15px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
    }
    .left_item {
      flex-wrap: wrap;
      // height: 590px;
      // height: 1639px;
      min-height: 400px;
      overflow: auto;
    }
    .list_item {
      width: 116px;

      height: 60px;
      background: #ffffff;
      text-align: center;
      // line-height: 40px;
      border-radius: 10px;
      margin-bottom: 10px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    .active {
      color: #ffffff;
      background: #226bff;
    }
  }

  .right {
    flex: 1;
    width: calc(100% - 279px);
    .right_header {
      .right_header_item {
        width: 143px;
        height: 43px;
        text-align: center;
        line-height: 43px;
        border-radius: 22px 22px 22px 22px;
        font-size: 24px;
        color: #101010;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        cursor: pointer;
      }
      .active_header {
        background: #5087ec;
        color: #ffffff;
      }
    }
    .right_main {
      // height: 1702px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #baccfb;
      margin-top: 15px;
      padding: 24px 20px;
      width: 100%;
      box-sizing: border-box;
      .right_title {
        font-size: 24px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #101010;
        margin-right: 33px;
      }
      .img_list {
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5087ec;
        cursor: pointer;
        img {
          width: 23px;
          height: 23px;
          margin-right: 9px;
        }
        .img1_1 {
          width: 21px;
          height: 24px;
        }
      }

      .performance {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.circleBox {
  position: relative;
  text-align: center;
  width: 170px;
  height: 170px;
  margin: 20px 0;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202020;
  .circleCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    div {
      font-size: 26px;
      color: #1360fb;
      font-weight: 600;
      margin-bottom: 5px;
    }
    span {
      font-size: 14px;
      color: #999999;
    }
  }
  .textCenter {
    position: absolute;
    left: 50%;
    top: 35%;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    transform: translate(-50%);
    .score {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #444444;
    }
  }
}

.ml-20 {
  margin-left: 20px;
}

.align-center {
  display: flex;
  align-items: center;
}

.min-w1400 {
  min-width: 1400px;
}
</style>