<template>
  <div class="contain">
    <div class="contain_card wb-100 h-400 align-center">
      <div class="wb-50 hb-100">
        <div class="box1_item_2 wh100" ref="range"></div>
      </div>
      <div class="wb-50 hb-100">
        <!-- <div class="wh100" ref="overallBoxPieRef"></div> -->
        <div class="box1_item_2 wh100" ref="range2"></div>
      </div>
    </div>
    <div class="contain_top_box align-center mt-40 mb-22 space-between">
      <div class="title_box align-center">
        <div class="title">全区中考模考概况</div>
      </div>
    </div>
    <div class="align-center h-350 wb-100" style="align-items: flex-start">
      <div
        class="wb-15 hb-100 preview-box1 column center pb-80 align-center bg1">
        <div class="perview_bottom align-center column">
          <div class="bottom_number">
            {{
              jyj_report && jyj_report.total_score
                ? jyj_report.total_score
                : "---"
            }}
          </div>
          <div class="bottom_text">总平均分</div>
        </div>
      </div>
      <div class="wb-85 hb-100 preview-box2 flex-wrap">
        <div
          class="sport_event bg1 relative hidden"
          v-for="(item, index) in sportsEventsList"
          :key="index">
          <div
            class="subscript"
            :class="`status${item.levelNum ? item.levelNum : 3}`">
            {{ item.levelName ? item.levelName : "---" }}
          </div>
          <div class="sport_event_card align-end column">
            <div class="top_name nowrap">{{ item.name }}</div>
            <div class="top_unit">({{ item.unit }})</div>
          </div>
          <div class="sport_event_card align-end space-between">
            <div class="icon_box align-center">
              <div class="icon" :style="item.iconStyle">
                <img :src="item.icon" alt="" />
              </div>
              <div class="score nowrap">
                {{ item.score ? item.score + "分" : "---" }}
              </div>
            </div>
            <div
              class=""
              :class="[timeRules.includes(item.key) ? 'fz-20' : 'value']">
              {{ item.value || "---" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Overall_Box_Pie_Option,
  Overall_Box_Bar_Option,
  Overall_Multple_Pie_Option,
  Sports_Events_Enum,
  Test_Conclusion_Echart,
  Subproject_Rate_Value_Echart,
  physical_Health_Trends_Echart,
  School_Excellence_Trends_Echart,
  School_Qualified_Trends_Echart,
  Grade_List,
  range,
} from "../../../enums/educationPhysicalEnum.js";
import * as echarts from "echarts";
export default {
  props: {
    isDialog: {
      type: Boolean,
      default: false,
    },
    overallData: {
      type: Object,
      default: {},
    },
    jyjBarData: {
      type: Array,
      default: {},
    },
    historicalList: {
      type: Array,
      default: [],
    },
    jyj_record_id: {
      type: Number | String,
      default: 0,
    },
    gradeList: {
      type: Array,
      default: [],
    },
    gender: {
      type: Number | String,
      default: "",
    },
    grade: {
      type: Number | String,
      default: "",
    },
    projectGender: {
      type: Number | String,
      default: "",
    },
    projectGrade: {
      type: Number | String,
      default: "",
    },
    scort: {
      type: Number | String,
      default: "",
    },
    projectAvgData: {
      type: Object,
      default: {},
    },
    reportRank: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      range: range,
      isHistorical: false,
      historical: "",
      historicalActive: 0,

      overallBoxPie: null,
      overallBoxPieOption: Overall_Multple_Pie_Option,

      overallBoxBar: null,
      overallBoxBarOption: Overall_Box_Bar_Option,

      sportsEventsList: Sports_Events_Enum,
      testConclusion: null,
      testConclusionEchart: Test_Conclusion_Echart,
      genderList: [
        {
          label: "全部",
          value: -1,
        },
        {
          label: "女",
          value: 0,
        },
        {
          label: "男",
          value: 1,
        },
      ],

      subprojectRateValue: null,
      subprojectRateValueEchart: Subproject_Rate_Value_Echart,

      physicalHealthList: [],

      scortList: [
        {
          label: "从高到低",
          value: "desc",
        },
        {
          label: "从低到高",
          value: "asc",
        },
      ],

      schoolNum: "",

      schoolExcellence: null,
      schoolExcellenceEchart: School_Excellence_Trends_Echart,

      schoolQualified: null,
      schoolQualifiedEchart: School_Qualified_Trends_Echart,
      timeRules: [
        "student_50meters_x8",
        "student_800meters",
        "student_1000meters",
      ],
    };
  },
  computed: {
    grade_level_report() {
      return this.overallData && this.overallData.grade_level_report
        ? this.overallData.grade_level_report
        : {};
    },

    jyj_report() {
      return this.overallData && this.overallData.jyj_report
        ? this.overallData.jyj_report
        : {};
    },

    jyj_report_str() {
      const { overallData } = this;
      return overallData.jyj_report_str;
    },

    jyj_name() {
      const jyjInfo = localStorage.getItem("user");
      return JSON.parse(jyjInfo).name || "";
    },
  },
  watch: {
    overallData: {
      handler(val) {
        this.setSportsEventsList();
        // this.setOverallBoxPieEchart();
        // this.setOverallBoxBarEchart();

        // this.setTestConclusion();
      },
      deep: true,
    },

    jyjBarData: {
      handler(newVal, oldVal) {
        if (!oldVal || !oldVal.length) {
          this.physicalHealthListInit();
        }
        this.$nextTick(() => {
          // this.setContainHealth(oldVal);
        });
      },
    },

    projectAvgData: {
      handler() {
        // this.setSubprojectRateValue();
      },
    },

    reportRank: {
      handler() {
        // this.setSchoolExcellence();
        // this.setSchoolQualified();
      },
    },
  },
  mounted() {
    // this.setOverallBoxPieEchart();
    this.handleInitrange();
    this.handleInitrange2();
    // this.setOverallBoxBarEchart();
    // this.setSportsEventsList();
    // this.setTestConclusion();
    // this.setSubprojectRateValue();
    // this.setSchoolExcellence();
    // this.setSchoolQualified();

    this.WatchResize();
  },
  methods: {
    handleInitrange(val) {
      let chartDom = this.$refs.range;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      // let myChart = echarts.init(chartDom);

      myChart.setOption(this.range);
    },
    handleInitrange2(val) {
      let chartDom = this.$refs.range2;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      // let myChart = echarts.init(chartDom);

      myChart.setOption(this.range);
    },
    handleSelect(record) {
      console.log(record, "record");
    },
    querySearch(queryString, cb) {
      var restaurants = [
        { value: "2", label: "2" },
        { value: "5", label: "5" },
        { value: "10", label: "10" },
      ];
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    setSportsEventsList() {
      const { jyj_report } = this;
      let list = JSON.parse(JSON.stringify(this.sportsEventsList));
      this.sportsEventsList = [];
      list.forEach((item, index) => {
        item.value = 0;
        item.score = 0;
        let objKeys = jyj_report ? Object.keys(jyj_report) : [];

        let keyIndex = objKeys.findIndex((key) => item.key == key);
        let scoreKeyIndex = objKeys.findIndex((key) => item.scoreKey == key);
        if (keyIndex !== -1) {
          item.value = jyj_report[objKeys[keyIndex]];
          item.levelNum = jyj_report[`${objKeys[keyIndex]}_level_num`];
          item.levelName = jyj_report[`${objKeys[keyIndex]}_level_name`];
        }

        if (scoreKeyIndex !== -1) {
          item.score = jyj_report[objKeys[scoreKeyIndex]];
          if (item.value) {
            this.sportsEventsList.push(item);
          }
        }
      });

      this.physicalHealthListInit();
      this.$nextTick(() => {
        // this.setContainHealth();
      });
    },

    handleScort(val) {
      this.$emit("handleScort", val);
    },

    handleTimeClick(item) {
      this.$emit("handleTimeClick", item.id);
      this.isHistorical = false;
    },

    handleGrade(val) {
      this.$emit("handleGrade", val);
    },

    handleGender(val) {
      this.$emit("handleGender", val);
    },

    handleProjectGrade(val) {
      this.$emit("handleProjectGrade", val);
    },

    handleProjectGender(val) {
      this.$emit("handleProjectGender", val);
    },

    getCurVal(val) {
      // this.schoolNum = val.target.value;
    },
    handleSize(val) {
      this.$emit("handleSize", val);
    },

    handlePreview() {
      this.$emit("handlePreview");
    },
    handleLookSchool() {
      this.$router.push("/physicaldetail");
    },

    setOverallBoxPieEchart() {
      let myChart = this.$refs.overallBoxPieRef;
      if (!this.overallBoxPie) {
        this.overallBoxPie = this.$echarts.init(myChart);
      }
      if (this.jyj_report) {
        // this.overallBoxPieOption.series[0].data.forEach((item, index) => {
        //   switch (index) {
        //     case 0:
        //       item.value = this.jyj_report.student_total_level_1;
        //       break;
        //     case 1:
        //       item.value =
        //         this.jyj_report.student_total_level_2 * 1 +
        //         this.jyj_report.student_total_level_3 * 1;
        //       break;
        //     case 2:
        //       item.value = this.jyj_report.student_total_level_4;
        //       break;
        //   }
        // });

        let passRate =
          (
            (this.jyj_report.student_total_level_1 * 1
              ? this.jyj_report.student_total_level_1 * 1
              : 0) +
            (this.jyj_report.student_total_level_2 * 1
              ? this.jyj_report.student_total_level_2 * 1
              : 0) +
            (this.jyj_report.student_total_level_3 * 1
              ? this.jyj_report.student_total_level_3 * 1
              : 0)
          ).toFixed(2) || 0;

        let excellentRate = this.jyj_report.student_total_level_1 || 0;

        let sumValue = 133.33;

        this.overallBoxPieOption.series.forEach((item, index) => {
          switch (index) {
            case 0:
              item.data[0].value = passRate;
              item.data[1].value = sumValue - passRate;
              break;
            case 2:
              item.data[0].value = excellentRate;
              item.data[1].value = sumValue - excellentRate;
            default:
              break;
          }
        });

        this.overallBoxPieOption.legend.formatter = function (name) {
          if (name == "合格率") {
            return "{title1|" + name + "} {value1|" + passRate + "%}";
          } else {
            return "{title2|" + name + "} {value2|" + excellentRate + "%}";
          }
        };
      }
      this.overallBoxPie.setOption(this.overallBoxPieOption);
    },

    setOverallBoxBarEchart() {
      let myChart = this.$refs.overallBoxBarRef;
      if (!this.overallBoxBar) {
        this.overallBoxBar = this.$echarts.init(myChart);
      }
      if (this.grade_level_report && this.grade_level_report.x_data) {
        this.overallBoxBarOption.yAxis.data = Grade_List.filter(
          (item) =>
            this.grade_level_report.x_data.findIndex(
              (key) => key == item.value
            ) !== -1
        ).map((item) => item.label);

        this.overallBoxBarOption.series.forEach((item, index) => {
          item.data = Object.values(this.grade_level_report.y_data[index]);
        });
      }
      this.overallBoxBar.setOption(this.overallBoxBarOption);
    },

    setTestConclusion() {
      const { jyj_report } = this;
      let myChart = this.$refs.testConclusionRef;
      if (!this.testConclusion) {
        this.testConclusion = this.$echarts.init(myChart);
      }

      let sportsArr = JSON.parse(JSON.stringify(Sports_Events_Enum));
      let screenArr = sportsArr.filter((item) => {
        jyj_report &&
          Object.keys(jyj_report).forEach((key) => {
            if (key == item.scoreKey) {
              item.score = jyj_report[key];
            }
          });

        return item.score;
      });

      let radarArr = screenArr && screenArr.length ? screenArr : sportsArr;

      this.testConclusionEchart.radar.indicator = radarArr.map((item) => {
        return {
          name: item.name,
          max: 100,
          min: 0,
        };
      });
      this.testConclusionEchart.series[0].data[0].value = radarArr.map(
        (item) => item.score
      );

      this.testConclusion.setOption(this.testConclusionEchart);
    },

    setSubprojectRateValue() {
      let myChart = this.$refs.subprojectRateValueRef;
      if (!this.subprojectRateValue) {
        this.subprojectRateValue = this.$echarts.init(myChart);
      }
      console.log(this.projectAvgData, "projectAvgData");
      if (this.projectAvgData.x_list) {
        let arrList = [];

        this.projectAvgData.x_list.forEach((key) => {
          Sports_Events_Enum.forEach((item) => {
            if (item.key == key) {
              arrList.push(item);
            }
          });
        });

        this.subprojectRateValueEchart.xAxis.data = arrList.map(
          (item) => item.name
        );

        this.subprojectRateValueEchart.series.forEach((item, index) => {
          item.data = this.projectAvgData.y_list[index];
        });
      }
      this.subprojectRateValue.setOption(this.subprojectRateValueEchart);
    },

    physicalHealthListInit() {
      const that = this;
      this.physicalHealthList = [];
      this.sportsEventsList.forEach((item, index) => {
        if (!that[`physicalHealth${index}`]) {
          that[`physicalHealth${index}`] = null;
        }

        let sportEcharts = {
          ...physical_Health_Trends_Echart,
          indepType: item.type,
          title: {
            text: item.name,
            left: "center", // 标题居中
            bottom: 0, // 距离上边框20像素
          },
        };

        this.physicalHealthList.push(sportEcharts);
      });
    },

    setContainHealth(oldVal = []) {
      let myCharts = this.$refs.containHealthRef;
      for (let index = 0; index < this.physicalHealthList.length; index++) {
        if (
          this[`physicalHealth${[index + 1]}`] == null ||
          !oldVal ||
          !oldVal.length
        ) {
          this[`physicalHealth${[index + 1]}`] = this.$echarts.init(
            myCharts[index]
          );
        }

        if (this.jyjBarData.length) {
          this.jyjBarData.forEach((item1) => {
            Object.values(item1).forEach((item2) => {
              if (this.physicalHealthList[index].indepType == item2.type) {
                this.physicalHealthList[index].xAxis.data = item2.x_data;
                this.physicalHealthList[index].series[0].data = item2.y_data;
              }
            });
          });
        } else {
          this.physicalHealthList[index].xAxis.data = [];
          this.physicalHealthList[index].series[0].data = [];
        }

        this[`physicalHealth${[index + 1]}`].setOption(
          this.physicalHealthList[index]
        );
      }
    },

    setSchoolExcellence() {
      let myChart = this.$refs.schoolExcellenceRef;
      if (!this.schoolExcellence) {
        this.schoolExcellence = this.$echarts.init(myChart);
      }
      if (this.reportRank.excellent_list) {
        this.schoolExcellenceEchart.yAxis.data =
          this.reportRank.excellent_list.map((item) => item.name);
        this.schoolExcellenceEchart.series[0].data =
          this.reportRank.excellent_list.map((item) => item.value);
      }
      this.schoolExcellence.setOption(this.schoolExcellenceEchart);
    },

    setSchoolQualified() {
      let myChart = this.$refs.schoolQualifiedRef;
      if (!this.schoolQualified) {
        this.schoolQualified = this.$echarts.init(myChart);
      }
      if (this.reportRank.pass_list) {
        this.schoolQualifiedEchart.yAxis.data = this.reportRank.pass_list.map(
          (item) => item.name
        );
        this.schoolQualifiedEchart.series[0].data =
          this.reportRank.pass_list.map((item) => item.value);
      }
      this.schoolQualified.setOption(this.schoolQualifiedEchart);
    },

    WatchResize() {
      const that = this;
      window.addEventListener("resize", function () {
        that.overallBoxPie.resize();
        that.overallBoxBar.resize();
        that.testConclusion.resize();
        that.subprojectRateValue.resize();
        that.schoolExcellence.resize();
        that.schoolQualified.resize();
        that.$nextTick(() => {
          for (let index = 0; index < that.physicalHealthList.length; index++) {
            that[`physicalHealth${[index + 1]}`].resize();
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../less/educationPhysical.less";
</style>
