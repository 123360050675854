<template>
  <div class="contain_box">
    <div class="main">
      <el-menu
        style="width: 100% !important"
        :default-active="activeIndex"
        class="el-menu-demo"
        router
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="/attainment">体质健康档案</el-menu-item>
        <el-menu-item index="/classExamination">中考模考</el-menu-item>
      </el-menu>
      <div class="flexSe">
        <div class="flexSt hader_2">
          <div class="hader_2_title">班级：</div>
          <el-select
            class="sel_t"
            @change="handleChanegclassId"
            style="margin-left: 25px"
            v-model="class_id"
            placeholder="请选择班级">
            <el-option
              v-for="item in classlist"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <el-popover
          placement="bottom"
          width="153"
          trigger="click"
          v-model="isHistorical">
          <div class="historical_select_time">
            <div
              class="select_time_link"
              :class="item.id == jyj_record_id ? 'select_time_active' : ''"
              @click="handleTimeClick(item)"
              v-for="(item, index) in historicalList"
              :key="index">
              {{ item.year }}
            </div>
          </div>
          <div
            slot="reference"
            class="align-center pointer img_list look_grade">
            <div class="icon_img center">
              <img src="@/assets/img/125.png" alt="" />
            </div>
            <div>统计年份</div>
          </div>
        </el-popover>
        <div class="look_grade" style="width: 15%" @click="handleLookGrade">
          <span>查看学生中考模考</span>
          <img class="img" src="@/assets/img/72.png" alt="" />
        </div>
      </div>

      <education-physical-contain
        :jyj_record_id="jyj_record_id"
        :historicalList="historicalList"
        :gradeList="gradeList"
        :gender="gender"
        :grade="grade"
        :projectGender="projectGender"
        :projectGrade="projectGrade"
        :scort="scort"
        :overallData="overallData"
        :jyjBarData="jyjBarData"
        :projectAvgData="projectAvgData"
        :reportRank="reportRank"
        @handleSize="handleSize"
        @handlePreview="handlePreview"
        @handleTimeClick="handleTimeClick"
        @handleScort="handleScort"
        @handleGrade="handleGrade"
        @handleGender="handleGender"
        @handleProjectGrade="handleProjectGrade"
        @handleProjectGender="handleProjectGender" />
    </div>
  </div>
</template>

<script>
import { getSchoolID } from "@/utils/auth";

import educationPhysicalContain from "./components/class/educationPhysicalContain";
import {
  getSchoolList,
  jyjPhysicalReport,
  jyjgetPhysicalDiagramData,
  jyjgetProjectAvgReport,
  jyjgetJyjSchoolReportRank,
  getLatestYears,
  getHistoricalMeasurement,
  jyjphysical,
  examination,
} from "@/api/physical";
export default {
  components: {
    educationPhysicalContain,
  },
  data() {
    return {
      class_id: "",
      className: "",
      isHistorical: false,
      classlist: [],
      activeIndex: "/classExamination",
      yearList: [],
      isDialog: false,
      schoolList: [],
      schoolName: "",
      school_id: "",
      jyj_record_id: "",

      gradeList: [],
      projectGender: -1,
      projectGrade: -1,

      gender: -1,
      grade: -1,

      overallData: {},
      jyjBarData: [],
      projectAvgData: {},
      reportRank: {},
      scort: "desc",
      size: 10,
      historicalList: [],
    };
  },
  computed: {
    jyj_id() {
      const jyjInfo = localStorage.getItem("user");
      return JSON.parse(jyjInfo).id;
    },

    // historicalList() {
    //   // 最近5年
    //   const date = new Date();
    //   const year = date.getFullYear();
    //   const list = [];

    //   for (let index = 0; index < 5; index++) {
    //     const obj = {
    //       record_time: year - index,
    //       id: year - index,
    //     };

    //     list.push(obj);
    //   }

    //   return list;
    // },
  },
  created() {
    this.school_id = getSchoolID();
    this.handlegetHistoricalMeasurement();
    this.getClasses();
  },
  methods: {
    async handleGetjyjphysical() {
      let data = {
        year: this.jyj_record_id,
        school_id: this.school_id,
        class_id: this.class_id,
      };
      await jyjphysical(data).then((res) => {
        this.overallData = res.data;
      });
    },
    handleTimeClick(item) {
      this.jyj_record_id = item.year;
      this.isHistorical = false;
      this.handleGetjyjphysical();
    },
    async handlegetHistoricalMeasurement() {
      const params = {
        school_id: this.school_id,
      };
      const { data } = await examination(params);

      this.historicalList = data;
      if (data && data.length) {
        this.jyj_record_id = data[0].year;
        this.jyj_record_time = data[0].year;
      }
      this.handleGetjyjphysical();
    },
    handleChanegclassId() {
      this.handleGetjyjphysical();
    },
    // 获取班级
    async getClasses(id) {
      const res = await this.axios.get(
        `/classes?all=1&school_id=${this.school_id}&level_id=9`
      );
      if (res.code === 1) {
        this.classlist = res.data;
        if (res.data.length > 0) {
          if (id) {
            this.class_id = id;
          } else {
            this.class_id = res.data[0].id;
          }
        } else {
          this.class_id = "";
          this.tableData = [];
        }
        this.handleGetjyjphysical();
      }
    },
    // 查看年级
    handleLookGrade() {
      // this.$router.push("/gradePhysical");
      this.$router.push("/studentExamination");
    },
    handleEducation() {
      this.$router.push("/educationExamination");
    },
    handleschoolEducation() {
      this.$router.push("/schoolExamination");
    },

    handleSize(val) {
      this.size = val ? val : 10;
      this.getJyjSchoolReportRank();
    },

    handleScort(val) {
      this.scort = val;
      this.getJyjSchoolReportRank();
    },

    handleProjectGrade(val) {
      this.projectGrade = val;
      this.getProjectAvgReport();
    },

    handleProjectGender(val) {
      this.projectGender = val;
      this.getProjectAvgReport();
    },

    handleGrade(val) {
      this.grade = val;
      this.getPhysicalDiagramData();
    },

    handleGender(val) {
      this.gender = val;
      this.getPhysicalDiagramData();
    },

    // 获取年级
    async getConfig() {
      let all = {
        name: "全部",
        id: -1,
        value: -1,
      };
      const res = await this.axios.get("/config/level");
      if (res.code === 1) {
        this.gradeList = [all, ...res.data];
      }
    },
    handleLookSchool() {
      // this.$router.push("/physicaldetail");
      this.$router.push("/classExamination");
    },
    handleClose() {
      this.isDialog = false;
    },
    handlePreview() {
      this.isDialog = true;
    },
    getCurVal(val) {
      // const obj = this.schoolList.find(item => item.id == this.school_id);
      // console.log(obj,'obj')
      // if(obj && obj.id){
      //   localStorage.setItem('school_id', this.school_id)
      //   // this.handleLookSchool();
      // }
    },
    getChange(val) {
      const obj = this.schoolList.find((item) => item.id == val);
      if (obj && obj.id) {
        localStorage.setItem("school_id", val);
        localStorage.setItem("school_Info", JSON.stringify(obj));
        this.handleLookSchool();
      }
    },

    async getSchoolList() {
      const params = {
        per_page: 1000000,
        page: 1,
      };
      const { code, data } = await getSchoolList(params);
      if (code == 1) {
        this.schoolList = data.data;
      }
    },

    async getJyjPhysicalReport() {
      const params = {
        year: this.jyj_record_id,
        jyj_id: this.jyj_id,
      };

      const { code, data } = await jyjPhysicalReport(params);

      if (code == 1) {
        // data.y_data
        this.removeEmptyOrNull(data.grade_level_report.y_data);

        data.grade_level_report.x_data = data.grade_level_report.x_data.splice(
          0,
          Object.keys(data.grade_level_report.y_data[0]).length
        );
        this.overallData = data;
      }
    },
    removeEmptyOrNull(obj) {
      for (let key in obj) {
        for (let key1 in obj[key]) {
          // console.log(key, key1, obj[key][key1], "obj");
          if (obj[key][key1] === null || obj[key][key1] === "") {
            delete obj[key][key1];
          }
        }
      }
      console.log(obj, "obj");
    },
    handleSelect(key, keyPath) {},
    async getPhysicalDiagramData() {
      const { jyj_id, grade, gender } = this;
      const params = {
        jyj_id,
        grade,
        gender,
      };

      const { data, code } = await jyjgetPhysicalDiagramData(params);

      if (code == 1) {
        this.jyjBarData = data;
      }
    },

    async getProjectAvgReport() {
      const { jyj_id, projectGrade, projectGender, jyj_record_id } = this;
      const params = {
        jyj_id,
        grade: projectGrade,
        gender: projectGender,
        year: jyj_record_id,
      };

      const { data, code } = await jyjgetProjectAvgReport(params);

      if (code == 1) {
        const projectAvgData = JSON.parse(
          JSON.stringify(data.project_avg_report)
        );
        // 获取所有下标为null的数据
        const nullArrs = [];
        projectAvgData.y_list.forEach((lists) => {
          const nullArr = [];
          lists.forEach((item, index) => {
            if (!item) {
              nullArr.push(index);
            }
          });
          nullArrs.push(nullArr);
        });

        // 重复出现的次数是y_list的长度
        const lent = projectAvgData.y_list.length;
        const repeatObj = {};
        if (nullArrs.length) {
          nullArrs.forEach((arrs) => {
            arrs.forEach((item) => {
              if (
                Object.keys(repeatObj).findIndex((record) => record == item) ==
                -1
              ) {
                repeatObj[item] = 1;
              } else {
                repeatObj[item] += 1;
              }
            });
          });
        }

        // 需要删除的下标
        const remoteIndexArr = [];
        Object.keys(repeatObj).forEach((item) => {
          if (repeatObj[item] == lent) {
            remoteIndexArr.push(item);
          }
        });

        // 判断数据是否都是 null 是的话就不删除
        let isRemove = false;
        projectAvgData.y_list.forEach((arrs) => {
          arrs.forEach((item) => {
            if (item) {
              return (isRemove = true);
            }
          });
        });

        if (isRemove && remoteIndexArr.length) {
          remoteIndexArr.forEach((item, index) => {
            projectAvgData.y_list.forEach((item1) => {
              item1.splice(item - index, 1);
            });
            projectAvgData.x_list.splice(item - index, 1);
          });

          this.projectAvgData = projectAvgData;
        } else {
          this.projectAvgData = data.project_avg_report;
        }
      }
    },

    async getJyjSchoolReportRank() {
      const { jyj_id, jyj_record_id, scort, size } = this;
      const params = {
        jyj_id,
        year: jyj_record_id,
        order: scort,
        size,
      };

      const { data, code } = await jyjgetJyjSchoolReportRank(params);

      if (code == 1) {
        this.reportRank = data;
        this.reportRank.excellent_list.reverse();
        this.reportRank.pass_list.reverse();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contain_box {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
  background-color: #f2f7ff;
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.hader_2_title {
  font-size: 20px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #101010;
}
.el-menu-item {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #232832;
}
.el-menu--horizontal {
  border: 0;
}
@import "../less/schoolPhysical.less";
// .contain_box {
//   padding: 20px;
//   background-color: #fff;
// }
.look_school {
  margin-top: 46px;
}
</style>
