export const Sports_Events_Enum = [
  {
    id: 1,
    name: "立定跳远",
    unit: "厘米",
    type: "STANDING_JUMP",
    key: "STANDING_JUMP",
    scoreKey: "STANDING_JUMP",
    value: 0,
    score: 0,
    icon: require("@/assets/img/223.png"),
    iconStyle: "width: 36px; height: 41px",
  },
  {
    id: 2,
    name: "足球运球",
    unit: "秒",
    type: "FOOTBALL",
    key: "FOOTBALL",
    scoreKey: "FOOTBALL",
    value: 0,
    score: 0,
    icon: require("@/assets/img/222.png"),
    iconStyle: "width: 50px; height: 55px",
  },
  {
    id: 3,
    name: "引体向上",
    unit: "次",
    type: "PULL_UP",
    key: "PULL_UP",
    scoreKey: "PULL_UP",
    value: 0,
    score: 0,
    icon: require("@/assets/img/221.png"),
    iconStyle: "width: 36px; height: 37px",
  },
  {
    id: 4,
    name: "1分钟仰卧起坐",
    unit: "个",
    type: "SIT_UP",
    key: "SIT_UP",
    scoreKey: "SIT_UP",
    value: 0,
    score: 0,
    icon: require("@/assets/img/224.png"),
    iconStyle: "width: 40px; height: 29px",
  },
  {
    id: 5,
    name: "1分钟跳绳",
    unit: "次",
    type: "JUMP",
    key: "JUMP",
    scoreKey: "JUMP",
    value: 0,
    score: 0,
    icon: require("@/assets/img/225.png"),
    iconStyle: "width: 27px; height: 41px",
  },
  {
    id: 6,
    name: "实心球",
    unit: "厘米",
    type: "MEDICINE_BALL",
    key: "MEDICINE_BALL",
    scoreKey: "MEDICINE_BALL",
    value: 0,
    score: 0,
    icon: require("@/assets/img/226.png"), //
    iconStyle: "width:39px; height: 46px",
  },
  {
    id: 7,
    name: "800米跑",
    unit: "秒",
    type: "RUNNING_800METERS",
    key: "RUNNING_800METERS",
    scoreKey: "RUNNING_800METERS",
    value: 0,
    score: 0,
    icon: require("@/assets/img/227.png"),
    iconStyle: "width: 45px; height: 49px",
  },
  {
    id: 8,
    name: "1000米跑",
    unit: "秒",
    type: "RUNNING_1000METERS",
    key: "RUNNING_1000METERS",
    scoreKey: "RUNNING_1000METERS",
    value: 0,
    score: 0,
    icon: require("@/assets/img/228.png"),
    iconStyle: "width: 49px; height: 49px",
  },
  {
    id: 9,
    name: "排球向上垫球",
    unit: "次",
    type: "VOLLEYBALL",
    key: "VOLLEYBALL",
    scoreKey: "VOLLEYBALL",
    value: 0,
    score: 0,
    icon: require("@/assets/img/229.png"), //
    iconStyle: "width:41px; height: 36px",
  },
  {
    id: 10,
    name: "200米游泳",
    unit: "秒",
    type: "SWIMMING_200METERS",
    key: "SWIMMING_200METERS",
    scoreKey: "SWIMMING_200METERS",
    value: 0,
    score: 0,
    icon: require("@/assets/img/230.png"), //
    iconStyle: "width: 48px; height: 47px",
  },
  {
    id: 11,
    name: "篮球运球",
    unit: "秒",
    type: "BASKETBALL",
    key: "BASKETBALL",
    scoreKey: "BASKETBALL",
    value: 0,
    score: 0,
    icon: require("@/assets/img/231.png"), //
    iconStyle: "width: 42px; height: 42px",
  },
];

export const range = {
  tooltip: {
    trigger: "item",

    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  legend: {
    bottom: "3%",
    left: "center",
    // formatter: function (name) {
    //   // 在这里编写自定义的格式化逻辑
    //   let value;
    //   for (let i = 0; i < sex_ratioData.length; i++) {
    //     // value = grade_ratioData[i].value;
    //     // console.log(i, name);
    //     if (name == sex_ratioData[i].name) {
    //       return name + "(" + sex_ratioData[i].value + "%" + ")";
    //     }
    //   }
    // },
  },
  series: [
    {
      name: "满分率",
      type: "pie",
      center: ["50%", "48%"],
      radius: "70%",
      avoidLabelOverlap: false,
      label: {
        show: true,
        position: "center",
        fontSize: 24,
        formatter: function (name) {
          // 在这里编写自定义的格式化逻辑
          let value;
          return name.value + "%";
        },
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      color: ["#8BD0DB"],
      labelLine: {
        show: false,
      },
      data: [{ value: 0, name: "优秀率" }],
    },
  ],
};

export const range2 = {
  tooltip: {
    trigger: "item",

    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  legend: {
    bottom: "3%",
    left: "center",
  },
  series: [
    {
      name: "及格率",
      type: "pie",
      center: ["50%", "48%"],
      radius: "70%",
      avoidLabelOverlap: false,
      label: {
        show: true,
        position: "center",
        fontSize: 24,
        formatter: function (name) {
          // 在这里编写自定义的格式化逻辑
          let value;
          return name.value + "%";
        },
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      color: ["#A8DBCE"],
      labelLine: {
        show: false,
      },
      data: [],
    },
  ],
};
