export const Attendance_options = {
    title: {
        text: "出勤率",
        left: "center", // 标题居中
        bottom: 8,
    },
    series: [{
        type: 'gauge',
        radius: '80%',
        startAngle: 90,
        endAngle: -270,
        pointer: {
            show: false
        },
        progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: true,
        },
        axisLine: {
            lineStyle: {
                width: 14,
                borderColor: '#5087ec'
            }
        },
        splitLine: {
            show: false,
            distance: 0,
            length: 10
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            show: false,
            distance: 5
        },
        color: "#5087ec",
        data: [
            {
                value: 0,
                detail: {
                    offsetCenter: ['10%', '0%']
                }
            },
        ],
        center: ['50%','40%'],
        detail: {
            width: 15,
            height: 6,
            fontSize: 18,
            color: '#333',
            borderColor: 'auto',
            fontFamily: "FinFont-Bold, FinFont",
            formatter: '{value}%'
        }
    }]
}

export const Jop_Completion_options = {
    title: {
        text: "作业完成率",
        left: "center", // 标题居中
        bottom: 8,
    },
    series: [{
        type: 'gauge',
        radius: '80%',
        startAngle: 90,
        endAngle: -270,
        pointer: {
            show: false
        },
        progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: true,
        },
        axisLine: {
            lineStyle: {
                width: 14,
                borderColor: '#5087ec'
            }
        },
        splitLine: {
            show: false,
            distance: 0,
            length: 10
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            show: false,
            distance: 5
        },
        color: "#5087ec",
        data: [
            {
                value: 0,
                detail: {
                    offsetCenter: ['10%', '0%']
                }
            },
        ],
        center: ['50%','40%'],
        detail: {
            width: 15,
            height: 6,
            fontSize: 18,
            color: '#333',
            borderColor: 'auto',
            fontFamily: "FinFont-Bold, FinFont",
            formatter: '{value}%'
        }
    }]
}


export const Classroom_Records_Column = [
    {
        prop:'classroomName',
        label:'课堂名称',
    },
    {
        prop:'classroomTime',
        label:'课堂时间',
        width: 234
    },
    {
        prop:'attendanceRecord',
        label:'出勤记录',
    },
    {
        prop:'maxRate',
        label:'最高心率',
    },
    {
        prop:'averageRate',
        label:'平均心率',
    },
    {
        prop:'deviceUsage',
        label:'设备使用'
    },
]

export const Jop_Records_Column = [
    {
        prop:'jobName',
        label:'作业名称',
    },
    {
        prop:'jobTime',
        label:'作业时间',
        width: 500
    },
    {
        prop:'jobContent',
        label:'作业内容',
    },
    {
        prop:'rate',
        label:'心率',
    },
    {
        prop:'status',
        label:'完成情况',
    },
]

export const Smart_Running_Column = [
    {
        prop:'RaceName',
        label:'跑团名称',
        width: 162
    },
    {
        prop:'exerciseTime',
        label:'运动时间',
        width: 334
    },
    {
        prop:'maxRate',
        label:'最高心率',
    },
    {
        prop:'averageRate',
        label:'平均心率',
    },
    {
        prop:'step',
        label:'步数',
    },
    {
        prop:'Rate120Time',
        label:'心率≥120运动时间',
    },
    {
        prop:'cadence',
        label:'步频（步/分）',
    },
    {
        prop:'pacing',
        label:'配速（分/公里）',
    },
    {
        prop:'totalKilometers',
        label:'总公里数',
    },
]

export const Event_Records_Column = [
    {
        prop:'eventName',
        label:'赛事名称',
        tooltip: true,
        width: 251
    },
    {
        prop:'eventTime',
        label:'赛事时间',
        width: 380
    },
    {
        prop:'event',
        label:'比赛项目',
    },
    {
        prop:'group',
        label:'组别',
    },
    {
        prop:'achievement',
        label:'成绩',
    },
    {
        prop:'ranking',
        label:'排名',
    },
]