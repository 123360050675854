<template>
  <div class="contain_box">
    <!-- <div class="look_school" @click="handleLookSchool()">
      查看学校体质健康详情
    </div> -->
    <div class="main">
      <div class="space-between">
        <div class="align-center class_header">
          <div class="align-center">
            <div @click="handleclassEducation" class="flexSt header">
              <img src="@/assets/img/126_1.png" alt="" />
              <div>班级中考模考</div>
            </div>
          </div>
        </div>
        <div class="flexSt hader_2">
          <div class="hader_2_title">班级：</div>
          <el-select
            class="sel_t"
            @change="handleChanegclassId"
            style="margin-left: 25px"
            v-model="class_id"
            placeholder="请选择班级">
            <el-option
              v-for="item in classlist"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-popover
            placement="bottom"
            width="153"
            trigger="click"
            v-model="isHistorical">
            <div class="historical_select_time">
              <div
                class="select_time_link"
                :class="item.id == jyj_record_id ? 'select_time_active' : ''"
                @click="handleTimeClick(item)"
                v-for="(item, index) in historicalList"
                :key="index">
                {{ item.year }}
              </div>
            </div>
            <div
              slot="reference"
              class="align-center pointer img_list look_grade">
              <div class="icon_img center">
                <img src="@/assets/img/125.png" alt="" />
              </div>
              <div>统计年份</div>
            </div>
          </el-popover>
        </div>
      </div>

      <div class="main flexSb">
        <div class="left">
          <!-- <div class="tiitle">学生列表</div> -->
          <el-tabs v-model="activeName" @tab-click="handleChangeStudent">
            <el-tab-pane label="全部" name="-1"></el-tab-pane>
            <el-tab-pane label="男生" name="1"></el-tab-pane>
            <el-tab-pane label="女生" name="0"></el-tab-pane>
          </el-tabs>
          <div class="left_item">
            <template v-if="tableData && tableData.length">
              <div
                @click="handleclick(index, item)"
                :class="{ active: index == current }"
                v-for="(item, index) in tableData"
                :key="index"
                class="list_item">
                <!-- <div>{{ item.id }}</div> -->
                <div>{{ item.name }}</div>
              </div>
            </template>
            <template v-else>
              <div class="wh100 center">
                <el-empty description="暂无数据"></el-empty>
              </div>
            </template>
          </div>
        </div>
        <div class="right">
          <div class="right_main">
            <div class="flexSb">
              <div class="flexSt">
                <div class="right_title">个人中考模考成绩</div>
              </div>
            </div>

            <div class="box1 flexSb">
              <div class="box1_left">
                <div class="box1_header flexSb">
                  <div class="name1">
                    {{
                      curentStudent && curentStudent.name
                        ? curentStudent.name.substr(
                            curentStudent.name.length - 1
                          )
                        : "---"
                    }}
                  </div>
                  <div>
                    <div class="name2">
                      姓名：{{
                        curentStudent && curentStudent.name
                          ? curentStudent.name
                          : "---"
                      }}
                    </div>
                    <div class="name2">
                      性别：
                      {{
                        curentStudent
                          ? curentStudent.gender == 0
                            ? "女"
                            : "男"
                          : "---"
                      }}
                    </div>
                    <div class="name2">
                      编号：{{
                        curentStudent && curentStudent.id
                          ? curentStudent.id
                          : "---"
                      }}
                    </div>
                    <div class="name2">
                      班级：{{ levelName || "---" }}
                      {{
                        curentStudent && curentStudent.class_name
                          ? curentStudent.class_name
                          : "---"
                      }}
                    </div>
                  </div>
                </div>
                <div class="box1_main">
                  <!-- <span v-if="student_data && student_data.total_score"
                    >{{ student_data.total_score }}分</span
                  ><span v-else>---</span> -->
                </div>
                <div class="box1_footer">
                  <div class="box1_bg">
                    <div class="hg">
                      {{
                        overallData && overallData.avg ? overallData.avg : "---"
                      }}
                    </div>
                    <img src="@/assets/img/114_1.png" alt="" />
                    <div class="zpf">总评分</div>
                  </div>
                </div>
              </div>
              <div class="box1_right align-center">
                <template v-if="sportsEventsList && sportsEventsList.length">
                  <div
                    class="prj"
                    v-for="(item, index) in sportsEventsList"
                    :key="index">
                    <div class="prj_header flexSb">
                      <div
                        class="status"
                        :class="`status${item.levelNum ? item.levelNum : 3}`">
                        <div class="status_title">
                          {{ item.levelName ? item.levelName : "---" }}
                        </div>
                      </div>
                      <div>
                        <div class="prj_name">{{ item.name }}</div>
                        <div class="prj_dw">（{{ item.unit }}）</div>
                      </div>
                    </div>
                    <div class="flexSb prj_st">
                      <div class="flexSt">
                        <img class="prj_img" :src="item.icon" alt="" />
                        <div>
                          {{
                            item.score || item.score == 0
                              ? `${item.score}分`
                              : "---"
                          }}
                        </div>
                      </div>
                      <div>
                        {{ item.value || item.value == 0 ? item.value : "---" }}
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="wh100 center min-w900">
                    <el-empty description="暂无数据"></el-empty>
                  </div>
                </template>
              </div>
            </div>

            <div class="flexSb">
              <div class="flexSt">
                <div class="right_title">训练计划100天</div>
              </div>
            </div>
            <div class="box2" style="border-color: ">
              <el-alert
                height="38"
                title="根据您的测试结果的优劣势项目，为您推送专属100天训练计划"
                type="warning"
                show-icon
                :closable="false">
              </el-alert>

              <div class="flexSt">
                <div
                  :class="{ active: current2 == index }"
                  v-for="(item, index) in datelist"
                  :key="index"
                  class="item"
                  @click="handleClickDay(item, index)">
                  {{ item.name }}
                </div>
              </div>
              <div class="flexSb">
                <div
                  class="list_i"
                  v-for="(item, index) in videolist"
                  :key="index">
                  <video
                    :autoplay="false"
                    :controls="true"
                    :src="item.url"></video>
                  <div class="flexSt">
                    <div
                      class="type"
                      :style="{ background: color[index] }"
                      :key="index"
                      v-for="(item, index) in item.tags">
                      {{ item }}
                    </div>
                  </div>

                  <div class="videotitle">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import educationPhysicalContain from "./components/student/educationPhysicalContain";
import {
  getSchoolList,
  jyjPhysicalReport,
  jyjgetPhysicalDiagramData,
  jyjgetProjectAvgReport,
  jyjgetJyjSchoolReportRank,
  getLatestYears,
  examination,
  jyjphysical,
  recommendedVideos,
} from "@/api/physical";
import { Sports_Events_Enum } from "../enums/educationExamination.js";
import { getSchoolID } from "@/utils/auth";
export default {
  components: {
    educationPhysicalContain,
  },
  data() {
    return {
      videolist: [],
      videoAll: [],
      current2: 0,
      sportsEventsList: [],
      isHistorical: false,
      student_data: null,
      levelName: "",
      curentStudent: {
        name: "",
        gender: "",
        class_name: "",
        id: "",
      },
      activeName: "-1",
      class_id: "",
      physicalHealthIndicator: [],
      tableData: [],
      tableData2: [],
      classlist: [],
      color: ["#FF9E0D", "#35CDFF"],
      current: 0,
      datelist: [
        {
          name: "1-20天",
          id: 0,
        },
        {
          name: "20-40天",
          id: 1,
        },
        {
          name: "40-60天",
          id: 2,
        },
        {
          name: "60-80天",
          id: 3,
        },
        {
          name: "80-100天",
          id: 4,
        },
      ],
      activeIndex: "/educationExamination",
      yearList: [],
      isDialog: false,
      schoolList: [],
      schoolName: "",
      school_id: "",
      jyj_record_id: "",
      gradeList: [],
      projectGender: -1,
      projectGrade: -1,

      gender: -1,
      grade: -1,

      overallData: {},
      jyjBarData: [],
      projectAvgData: {},
      reportRank: {},
      scort: "desc",
      size: 10,
      historicalList: [],
    };
  },
  computed: {
    jyj_id() {
      const jyjInfo = localStorage.getItem("user");
      return JSON.parse(jyjInfo).id;
    },
  },
  created() {
    this.school_id = getSchoolID();
    this.getClasses();
    this.handlegetHistoricalMeasurement();
    this.handleGetrecommendedVideos();
  },
  methods: {
    async handleGetrecommendedVideos() {
      let data = {
        year: this.jyj_record_id,
        student_id: this.curentStudent.id,
      };
      await recommendedVideos(data).then((res) => {
        if (res.data.length > 0) {
          this.videolist = [res.data[0]];
          this.videoAll = res.data;
        } else {
          this.videoAll = [];
          this.videolist = [];
        }
        console.log(this.videolist, "视频");
      });
    },
    setSportsEventsList() {
      const { overallData } = this;
      console.log(overallData, "111111111111=jyj_report");

      let list = JSON.parse(JSON.stringify(Sports_Events_Enum));
      this.sportsEventsList = [];
      list.forEach((item, index) => {
        item.value = 0;
        item.score = 0;
        let objKeys = overallData.info ? overallData.info : [];
        // objKeys, jyj_report.info,
        let keyIndex = objKeys.findIndex((key) => item.type == key.type);
        let scoreKeyIndex = objKeys.findIndex((key) => item.type == key.type);

        if (keyIndex !== -1) {
          item.value = objKeys[keyIndex].val;
          item.levelNum = objKeys[keyIndex].level;
          item.levelName = objKeys[keyIndex].level_name;
          item.score = objKeys[scoreKeyIndex].score;
          // if (item.value) {
          this.sportsEventsList.push(item);
        }
      });
    },
    async handleGetjyjphysical() {
      let data = {
        year: this.jyj_record_id,
        school_id: this.school_id,
        class_id: this.class_id,
        student_id: this.curentStudent.id,
      };
      await jyjphysical(data).then((res) => {
        this.overallData = res.data;
        this.setSportsEventsList();
      });
    },
    handleChanegclassId() {
      this.getStudent();
    },
    handleChangeStudent($event) {
      const { tableData2 } = this;
      const index = $event.index * 1;
      switch (index) {
        case 1:
          // 男生
          this.tableData = tableData2.filter((item) => item.gender == 1);
          break;
        case 2:
          // 女生
          this.tableData = tableData2.filter((item) => item.gender == 0);
          break;
        default:
          this.tableData = tableData2;
          break;
      }
      this.handleclick(0, this.tableData[0]);
    },
    handleclick(index, item) {
      this.current = index;
      this.curentStudent = item;
      this.handleGetjyjphysical();
      this.handleGetrecommendedVideos();
    },
    // 获取班级下学生数据
    async getStudent() {
      const res = await this.axios.get(
        `/school/students/list?classes_id=${this.class_id}&school_id=${this.school_id}&grade=9`
      );

      if (res.code === 1) {
        this.tableData = res.data.data;
        this.tableData2 = res.data.data;
        this.curentStudent = res.data.data[0];

        this.handleGetjyjphysical();
        console.log(this.tableData.length);

        // if (this.tableData.length <= 0) {
        //   this.dialogImportClass = true;
        // }
      }
    },
    // 获取班级
    async getClasses(id) {
      const res = await this.axios.get(
        `/classes?all=1&school_id=${this.school_id}&level_id=9`
      );
      if (res.code === 1) {
        this.classlist = res.data;
        if (res.data.length > 0) {
          if (id) {
            this.class_id = id;
            this.getStudent();
          } else {
            this.class_id = res.data[0].id;
            this.getStudent();
          }
        } else {
          this.class_id = "";
          this.tableData = [];
          this.handleGetprojectType();
        }
        this.handleGetjyjphysical();
      }
    },
    handleclassEducation() {
      this.$router.push("/classExamination");
    },
    handleschoolEducation() {
      this.$router.push("/classExamination");
    },
    handleClickDay(item, index) {
      this.current2 = index;
      this.videolist = [this.videoAll[index]];
    },
    // 查看年级
    handleLookGrade() {
      // this.$router.push("/gradePhysical");
      this.$router.push("/studentExamination");
    },
    handleEducation() {
      this.$router.push("/educationExamination");
    },
    async handlegetHistoricalMeasurement() {
      const params = {
        school_id: this.school_id,
      };
      const { data } = await examination(params);

      this.historicalList = data;
      if (data && data.length) {
        this.jyj_record_id = data[0].year;
        this.jyj_record_time = data[0].year;
      }
      this.handleGetjyjphysical();
    },
    handleSize(val) {
      this.size = val ? val : 10;
      this.getJyjSchoolReportRank();
    },

    handleScort(val) {
      this.scort = val;
      this.getJyjSchoolReportRank();
    },

    handleProjectGrade(val) {
      this.projectGrade = val;
      this.getProjectAvgReport();
    },

    handleProjectGender(val) {
      this.projectGender = val;
      this.getProjectAvgReport();
    },

    handleGrade(val) {
      this.grade = val;
      this.getPhysicalDiagramData();
    },

    handleGender(val) {
      this.gender = val;
      this.getPhysicalDiagramData();
    },

    handleTimeClick(item) {
      this.jyj_record_id = item.year;
      this.isHistorical = false;
      this.handleGetjyjphysical();
    },
    // 获取年级
    async getConfig() {
      let all = {
        name: "全部",
        id: -1,
        value: -1,
      };
      const res = await this.axios.get("/config/level");
      if (res.code === 1) {
        this.gradeList = [all, ...res.data];
      }
    },
    handleLookSchool() {
      // this.$router.push("/physicaldetail");
      this.$router.push("/classExamination");
    },
    handleClose() {
      this.isDialog = false;
    },
    handlePreview() {
      this.isDialog = true;
    },
    getCurVal(val) {
      // const obj = this.schoolList.find(item => item.id == this.school_id);
      // console.log(obj,'obj')
      // if(obj && obj.id){
      //   localStorage.setItem('school_id', this.school_id)
      //   // this.handleLookSchool();
      // }
    },
    getChange(val) {
      const obj = this.schoolList.find((item) => item.id == val);
      if (obj && obj.id) {
        localStorage.setItem("school_id", val);
        localStorage.setItem("school_Info", JSON.stringify(obj));
        this.handleLookSchool();
      }
    },

    async getSchoolList() {
      const params = {
        per_page: 1000000,
        page: 1,
      };
      const { code, data } = await getSchoolList(params);
      if (code == 1) {
        this.schoolList = data.data;
      }
    },

    async getJyjPhysicalReport() {
      const params = {
        year: this.jyj_record_id,
        jyj_id: this.jyj_id,
      };

      const { code, data } = await jyjPhysicalReport(params);

      if (code == 1) {
        // data.y_data
        this.removeEmptyOrNull(data.grade_level_report.y_data);

        data.grade_level_report.x_data = data.grade_level_report.x_data.splice(
          0,
          Object.keys(data.grade_level_report.y_data[0]).length
        );
        this.overallData = data;
      }
    },
    removeEmptyOrNull(obj) {
      for (let key in obj) {
        for (let key1 in obj[key]) {
          // console.log(key, key1, obj[key][key1], "obj");
          if (obj[key][key1] === null || obj[key][key1] === "") {
            delete obj[key][key1];
          }
        }
      }
      console.log(obj, "obj");
    },
    handleSelect(key, keyPath) {},
    async getPhysicalDiagramData() {
      const { jyj_id, grade, gender } = this;
      const params = {
        jyj_id,
        grade,
        gender,
      };

      const { data, code } = await jyjgetPhysicalDiagramData(params);

      if (code == 1) {
        this.jyjBarData = data;
      }
    },

    async getProjectAvgReport() {
      const { jyj_id, projectGrade, projectGender, jyj_record_id } = this;
      const params = {
        jyj_id,
        grade: projectGrade,
        gender: projectGender,
        year: jyj_record_id,
      };

      const { data, code } = await jyjgetProjectAvgReport(params);

      if (code == 1) {
        const projectAvgData = JSON.parse(
          JSON.stringify(data.project_avg_report)
        );
        // 获取所有下标为null的数据
        const nullArrs = [];
        projectAvgData.y_list.forEach((lists) => {
          const nullArr = [];
          lists.forEach((item, index) => {
            if (!item) {
              nullArr.push(index);
            }
          });
          nullArrs.push(nullArr);
        });

        // 重复出现的次数是y_list的长度
        const lent = projectAvgData.y_list.length;
        const repeatObj = {};
        if (nullArrs.length) {
          nullArrs.forEach((arrs) => {
            arrs.forEach((item) => {
              if (
                Object.keys(repeatObj).findIndex((record) => record == item) ==
                -1
              ) {
                repeatObj[item] = 1;
              } else {
                repeatObj[item] += 1;
              }
            });
          });
        }

        // 需要删除的下标
        const remoteIndexArr = [];
        Object.keys(repeatObj).forEach((item) => {
          if (repeatObj[item] == lent) {
            remoteIndexArr.push(item);
          }
        });

        // 判断数据是否都是 null 是的话就不删除
        let isRemove = false;
        projectAvgData.y_list.forEach((arrs) => {
          arrs.forEach((item) => {
            if (item) {
              return (isRemove = true);
            }
          });
        });

        if (isRemove && remoteIndexArr.length) {
          remoteIndexArr.forEach((item, index) => {
            projectAvgData.y_list.forEach((item1) => {
              item1.splice(item - index, 1);
            });
            projectAvgData.x_list.splice(item - index, 1);
          });

          this.projectAvgData = projectAvgData;
        } else {
          this.projectAvgData = data.project_avg_report;
        }
      }
    },

    async getJyjSchoolReportRank() {
      const { jyj_id, jyj_record_id, scort, size } = this;
      const params = {
        jyj_id,
        year: jyj_record_id,
        order: scort,
        size,
      };

      const { data, code } = await jyjgetJyjSchoolReportRank(params);

      if (code == 1) {
        this.reportRank = data;
        this.reportRank.excellent_list.reverse();
        this.reportRank.pass_list.reverse();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.list_i {
  width: 380px;
}
video {
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  margin-top: 28px;
}
.type {
  border-radius: 4px 4px 4px 4px;
  // width: 61px;
  height: 23px;
  line-height: 23px;
  padding: 0 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}
.videotitle {
  font-weight: 400;
  font-size: 16px;
  color: #101010;
  margin-top: 6px;
}

.item {
  width: 77px;
  height: 28px;
  background: #eef1f6;
  border-radius: 4px 4px 4px 4px;
  line-height: 28px;
  text-align: center;
  font-size: 14px;
  color: #748492;
  margin-right: 14px;
  cursor: pointer;
}
.active {
  background: #226bff;
  color: #ffffff;
}
.el-alert--warning.is-light {
  height: 38px;
  padding-left: 21px;
  font-size: 14px;
  margin-bottom: 16px;
}
.class_header {
  width: 50% !important;
}
.hader_2_title {
  font-size: 20px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #101010;
}
.el-menu-item {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #232832;
}
.el-menu--horizontal {
  border: 0;
}
@import "../less/schoolPhysical.less";
.contain_box {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
  background-color: #f2f7ff;
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.look_school {
  margin-top: 46px;
}
</style>
<style lang="less" scoped>
.t-c {
  color: #ccc !important;
}
.min-w900 {
  min-width: 900px;
}
.box1 {
  margin-bottom: 26px;
  margin-top: 21px;
  align-items: flex-start;
  .box1_left {
    width: 250px;
    height: 302px;
    background: #f1f3fd;
    border-radius: 10px 10px 10px 10px;
    padding: 15px 17px;
    box-sizing: border-box;
    margin-right: 10px;
    .box1_header {
      .name1 {
        font-size: 40px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #101010;
        width: 80px;
        height: 80px;
        border-radius: 0px 0px 0px 0px;
        border: 1px dashed #5087ec;
        box-sizing: border-box;
        text-align: center;
        line-height: 80px;
        margin-right: 4px;
      }
      .name2 {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #101010;
        white-space: nowrap;
      }
    }
    .box1_main {
      font-size: 40px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #101010;
      text-align: center;
      height: 40px;
    }
    .box1_footer {
      .box1_bg {
        position: relative;
        .hg {
          position: absolute;
          font-size: 26px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #5087ec;
          text-align: center;
          width: 100%;
        }
        .zpf {
          text-align: center;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #101010;
        }
        img {
          width: 180px;
          height: 91px;
          margin: 0 auto;
        }
      }
    }
  }
  .box1_right {
    flex: 1;
    flex-wrap: wrap;
    .prj {
      width: 24%;
      height: 146px;
      background: #f1f3fd;
      border-radius: 10px 10px 10px 10px;
      padding-right: 10px;
      box-sizing: border-box;
      margin-right: 10px;
      margin-bottom: 10px;
      &:nth-child(4n) {
        margin-right: 0px;
        margin-bottom: 0px;
      }
      .prj_header {
        .status {
          width: 74px;
          height: 73px;
          background-image: url("../../../assets/img/128.png");
          background-size: 100% 100%;
          background-color: transparent;
          box-sizing: border-box;
        }
        .status1 {
          background-image: url("../../../assets/img/129.png");
        }
        .status2 {
          background-image: url("../../../assets/img/130.png");
        }
        .status3 {
          background-image: url("../../../assets/img/128.png");
        }
        .status4 {
          background-image: url("../../../assets/img/131.png");
          .status_title {
            margin-top: 6px;
          }
        }
        .status_title {
          transform: rotate(312deg);
          margin-right: 5px;
          font-size: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
        .prj_name {
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #101010;
          text-align: end;
        }
        .prj_dw {
          font-size: 15px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #101010;
          text-align: end;
        }
      }
      .prj_img {
        width: 32px;
        height: 42px;
        margin-right: 7px;
      }
      .prj_img1 {
        width: 34px;
        height: 31px;
      }
      .prj_img3 {
        width: 34px;
        height: 24px;
      }
      .prj_st {
        font-size: 28px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #101010;
        margin-top: 18px;
        padding-left: 10px;
        box-sizing: border-box;
      }
    }
    .prj1 {
      margin-top: 10px;
    }
  }
}

.box2 {
  margin-top: 22px;
  margin-bottom: 26px;
  align-items: flex-start;
  width: 100%;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #baccfb;
  box-sizing: border-box;
  padding: 28px 26px;
  box-sizing: border-box;
  .box2_left {
    width: 60%;
    font-size: 19px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #101010;
  }
  .cont_box2 {
    margin-bottom: 10px;
  }
  .box2_right {
    flex: 1;
    height: 300px;
  }
}
.box3 {
  width: 100%;
  min-height: 785px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #baccfb;
  box-sizing: border-box;
  margin-top: 20px;
  flex-wrap: wrap;
  display: flex;

  .box3_item {
    width: 32.5%;
    // height: 50%;
    height: 392px;
    box-sizing: border-box;
  }
}
.tip_jy {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-top: 10px;
}
.box222 {
  // border: 1px solid;
  width: 100%;
  position: relative;
}
.box_1 {
  width: 250px;
  height: 160px;
}
.header_box {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #226bff;
  align-items: center;
  // border: 1px solid;
  box-sizing: border-box;
  margin-bottom: 28px;
  .cl_ti {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #202020;
  }
  .hader_2 {
    .hader_2_title {
      font-size: 20px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #101010;
    }
    .sel_t {
      width: 120px;
      // height: 35px;
      border-radius: 5px 5px 5px 5px;
      // border: 1px solid #dfe5ec;
      box-sizing: border-box;
    }
  }
}
.header {
  cursor: pointer;
  margin-right: 25px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  // margin-top: 38px;

  img {
    width: 23px;
    height: 23px;
    margin-right: 15px;
  }
}
.main {
  margin-top: 10px;
  align-items: flex-start;
  .left {
    width: 279px;
    // height: 625px;
    // height: 671px;
    // height: 1760px;
    height: 100%;
    background: #f1f3fd;
    border-radius: 15px;
    // margin-right: 52px;
    margin-right: 12px;
    padding: 18px 18px;
    box-sizing: border-box;
    /deep/.el-tabs__nav-wrap::after {
      border: 0;
    }

    /deep/.el-tabs__active-bar {
      background: #101010;
    }
    /deep/.el-tabs__item {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #777777;
    }
    /deep/ .is-active {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #101010;
    }
    .tiitle {
      margin-bottom: 15px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
    }
    .left_item {
      flex-wrap: wrap;
      // height: 590px;
      // height: 1639px;
      min-height: 400px;
      overflow: auto;
      display: flex;
      align-content: flex-start;
      justify-content: space-between;
    }
    .list_item {
      width: 116px;
      height: 40px;
      background: #ffffff;
      text-align: center;
      line-height: 40px;
      border-radius: 10px;
      margin-bottom: 10px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    .active {
      color: #ffffff;
      background: #226bff;
    }
  }

  .right {
    flex: 1;
    width: calc(100% - 290px);
    .right_header {
      .right_header_item {
        width: 143px;
        height: 43px;
        text-align: center;
        line-height: 43px;
        border-radius: 22px 22px 22px 22px;
        font-size: 24px;
        color: #101010;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        cursor: pointer;
      }
      .active_header {
        background: #5087ec;
        color: #ffffff;
      }
    }
    .right_main {
      // height: 1702px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #baccfb;
      margin-top: 15px;
      padding: 24px 20px;
      width: 100%;
      box-sizing: border-box;
      .right_title {
        font-size: 24px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #101010;
        margin-right: 33px;
      }
      .img_list {
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5087ec;
        cursor: pointer;
        img {
          width: 23px;
          height: 23px;
          margin-right: 9px;
        }
        .img1_1 {
          width: 21px;
          height: 24px;
        }
      }

      .performance {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.circleBox {
  position: relative;
  text-align: center;
  width: 170px;
  height: 170px;
  margin: 20px 0;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202020;
  .circleCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    div {
      font-size: 26px;
      color: #1360fb;
      font-weight: 600;
      margin-bottom: 5px;
    }
    span {
      font-size: 14px;
      color: #999999;
    }
  }
  .textCenter {
    position: absolute;
    left: 50%;
    top: 35%;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    transform: translate(-50%);
    .score {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #444444;
    }
  }
}

.ml-20 {
  margin-left: 20px;
}

.align-center {
  display: flex;
  align-items: center;
}

.icon_img {
  width: 20px;
  height: 23px;
  margin-right: 4px;

  img {
    width: 100%;
    height: 100%;
  }
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}
.historical_select_time {
  // padding: 0 27px;
  width: 100%;

  .select_time_link {
    width: 100%;
    padding: 18px 0 17px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #3f3f3f;
    border-bottom: 1px solid #dedede;
    white-space: nowrap;
    justify-content: center;
    cursor: pointer;

    &:nth-last-child(1) {
      border-bottom-width: 0px;
    }
  }

  .select_time_active {
    color: #5087ec;
  }
}

.wh100 {
  width: 100%;
  height: 100%;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
