<template>
  <transition name="dialog-fade">
    <div class="wrapper" v-if="isReportDialog">
      <div class="dialog">
        <div class="dialog_close">
          <div class="click_dom" @click="handleExport">
            <div class="icon">
              <img src="@/assets/img/124.png" alt="" />
            </div>
            <span>导出</span>
          </div>

          <div class="click_dom" @click="handleClose">
            <div class="icon">
              <img src="@/assets/img/123.png" alt="" />
            </div>
            <span>关闭</span>
          </div>
        </div>
        <div class="dialog_box">
          <div class="dialog-body" ref="dialogBodyRef" id="contentToExport">
            <div class="dialog_title">
              体质健康档案——{{
                curentStudent && curentStudent.name ? curentStudent.name : "---"
              }}
            </div>
            <div class="dialog_contain">
              <report-dialog-body
                :isReportDialog="isReportDialog"
                :school_record_time="school_record_time"
                :physicalHealthIndicator="physicalHealthIndicator"
                :trendBarList="trendBarList"
                :student_data="student_data"
                :compare_data="compare_data"
                :curentStudent="curentStudent"
                :echartsOption="echartsOption"
                :levelName="levelName"
                :personal_total_report_str="personal_total_report_str"
                :personal_part_str="personal_part_str"
                :personal_compare_str="personal_compare_str"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import htmlPdf from "@/utils/pdf.js";
import ReportDialogBody from "./ReportDialogBody.vue";
export default {
  components: {
    ReportDialogBody,
  },
  props: {
    isReportDialog: {
      type: Boolean,
      default: false,
    },
    school_record_time:{
      type:String,
      default:''
    },
    physicalHealthIndicator:{
      type: Array,
      default: []
    },
    trendBarList:{
      type: Array,
      default: []
    },
    levelName: {
      type: String,
      default: "",
    },
    personal_total_report_str: {
      type: String,
      default: "",
    },
    personal_part_str: {
      type: String,
      default: "",
    },
    personal_compare_str: {
      type: String,
      default: "",
    },
    student_data: {
      type: Object,
      default: {},
    },
    compare_data: {
      type: Object,
      default: {},
    },
    curentStudent: {
      type: Object,
      default: {},
    },
    echartsOption: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  computed: {
    studentName() {
      const { curentStudent } = this;
      return curentStudent && curentStudent.name
        ? curentStudent.name.substr(curentStudent.name.length - 1)
        : "---";
    },
  },
  mounted() {},
  watch: {},
  methods: {
    async handleExport() {
      let name =
        this.curentStudent && this.curentStudent.name
          ? "体质健康档案——" + this.curentStudent.name
          : "体质健康档案";
      let dom = document.getElementById("contentToExport");
      htmlPdf(name, dom);
    },
    handleClose() {
      this.$emit("handleClose", false);
    },
  },
};
</script>

<style lang='less' scoped>
.wb100 {
  width: 100%;
  height: 100%;
}
.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 2068;
  background-color: rgba(0, 0, 0, 0.5);
}

img {
  width: 100%;
  height: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  position: relative;
  margin: 0 auto 50px;
  width: 80%;
  top: 4%;
  box-sizing: border-box;
  .dialog_close {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 23px;
    .click_dom {
      display: flex;
      align-items: center;
      color: #fff;
      cursor: pointer;
      margin-left: 38px;
      font-size: 22px;
      .icon {
        width: 35px;
        height: 35px;
        margin-right: 13px;
      }
    }
  }
}

.dialog_title {
  width: 100%;
  height: 127px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  background-color: #fff;
}
.dialog_contain {
  margin-left: 40px;
  margin-right: 40px;
  padding: 26px 20px 20px 30px;
  border-radius: 10px;
  border: 1px solid #baccfb;
  box-sizing: border-box;
  background-color: #fff;
}

.dialog_contain {
  background-color: #fff;
}

.dialog_box {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.ml-20 {
  margin-left: 20px;
}
</style>